// src/pages/AgentList/ListPage/component/EmployeeTable.js

import React, {useState, useEffect, useCallback, useRef} from "react";
import {
    Table,
    Switch,
    Space,
    Button,
    Message,
    Modal,
    Dropdown,
    Menu, Avatar,
} from "@arco-design/web-react";
import {
    IconWechat,
    IconDown,
    IconUp,
    IconPlus,
} from "@arco-design/web-react/icon";
import {
    addAssistantApi, // 添加员工的接口
    getAssistantsWithWeChatApi,
    updateWechatAutoReplyApi,
    deleteAssistantApi,
    getWeChats,
    updateAssistantApi,
} from "../../../../api/normalApi";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setSelectedEmployee} from "../../../../store/slices/userSlice";

import {useResponsive} from "../../../../utils/useResponsive";

import OpenAI from "openai";
import WechatLogin from "../../EditPage/components/WechatLogin/WechatLogin";

const openai = new OpenAI({
    baseURL: "https://baseurl.replit.app",
    apiKey: "",
    dangerouslyAllowBrowser: true,
});

// 在组件顶部添加配置映射
const GROUP_CONFIG_MAP = {
    1: "全部都回复",
    2: "被@才回复",
    3: "被@后总回",
};

const NEW_CHAT_CONFIG_MAP = {
    1: "全部都回复",
    2: "仅私聊回复",
    3: "仅群聊回复",
};

// 员工列表组件
const EmployeeTable = ({maxEmployeeSeats, maxClientSeats}) => {
    const navigate = useNavigate();
    const [employeeList, setEmployeeList] = useState([]);
    const [intervalActive, setIntervalActive] = useState(false);
    const [previousUpdatedAt, setPreviousUpdatedAt] = useState({}); // 存储每个账号的 updated_at
    const [groupDropdownOpen, setGroupDropdownOpen] = useState({}); // 群聊回复的下拉状态
    const [newChatDropdownOpen, setNewChatDropdownOpen] = useState({}); // 新聊天的下拉状态
    const [groupSwitchChecked, setGroupSwitchChecked] = useState({}); // 群聊回复开关状态
    const [newChatSwitchChecked, setNewChatSwitchChecked] = useState({}); // 新聊天开关状态
    const [globalSwitchChecked, setGlobalSwitchChecked] = useState({}); // 全局回复开关状态
    const [privateSwitchChecked, setPrivateSwitchChecked] = useState({}); // 私聊回复开关状态
    const [isQrcodeModalVisible, setIsQrcodeModalVisible] = useState(false);

    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [currentLoginAccount, setCurrentLoginAccount] = useState(null);

    const dispatch = useDispatch();
    const {isMobile} = useResponsive();


    // 处理登录成功回调
    const handleLoginSuccess = async (account, config) => {
        if (account) {
            // 已有账号重新登录
            await updateWechatAutoReplyApi({
                wx_id: account.wx_id,
                is_online: true,
                private_auto_reply: config.private_auto_reply,
                group_auto_reply: config.group_auto_reply
            });
        }
        setIsLoginModalVisible(false);
        setCurrentLoginAccount(null);
        await getAssistantsWxIds();
    };

    // 修改登录点击处理函数
    const handleLoginClick = (account) => {
        setIsLoginModalVisible(true);
        setCurrentLoginAccount(account);
    };

    // 工具栏中的"登录微信"按钮点击处理
    const handleLoginModal = () => {
        const currentWxCount = calculateCurrentWxAccounts();
        if (currentWxCount >= maxClientSeats) {
            Message.warning('已达到最大微信账号数量限制');
            return;
        }
        setIsLoginModalVisible(true);
        setCurrentLoginAccount(null);
    };

    const handleDeleteAssistant = async (assistantId) => {
        Modal.confirm({
            title: "确认删除该员工吗？",
            content: (
                <div style={{textAlign: "center"}}>
                    <p>删除后，该员工的所有信息将无法恢复</p>
                </div>
            ),
            onOk: async () => {
                try {
                    const employee = employeeList.find(
                        (employee) => employee.id === assistantId
                    );
                    if (employee.assistant.openai_vs_id) {
                        const deletedVectorStore = await openai.beta.vectorStores.del(
                            employee.assistant.openai_vs_id
                        );
                    }
                    const data = await deleteAssistantApi(assistantId);
                    if (data.status === 200 || data.status === "success") {
                        await getAssistantsWxIds().then(Message.success("删除员工成功"));
                    } else {
                        Message.error("删除员工失败，请稍后重试");
                    }
                } catch (error) {
                    Message.error("删除员工失败，请稍后重试");
                }
            },
        });
    };

    // 处理员工和微信账号数据
    const processEmployeeData = (data, unboundedData) => {
        if (data === null) return [];
        let result = [];
        data.forEach((employee) => {
            if (employee.wechats.length === 0) {
                // 查找 unboundedData 中与当前 employee 匹配的记录
                const unbounded = unboundedData.find(
                    (unbound) => unbound.assistant_id === employee.assistant.id
                );

                result.push({
                    ...employee,
                    id: employee.assistant.id, // 确保有 id 字段
                    name: employee.assistant.name,
                    wx_id: null,
                    nickname: "未绑定",
                    auto_reply: unbounded ? unbounded.auto_reply : false,
                    is_online: unbounded ? unbounded.is_online : false,
                });
            } else {
                employee.wechats.forEach((wechat, index) => {
                    result.push({
                        ...wechat,
                        ...employee,
                        id: employee.assistant.id, // 确保有 id 字段
                        name: index === 0 ? employee.assistant.name : "",
                        wx_id: wechat.wx_id,
                        nickname: wechat.nickname,
                        auto_reply: wechat.auto_reply,
                        is_online: wechat.is_online,
                    });
                });
            }
        });

        if (unboundedData) {
            // 处理没有绑定助手的微信账号
            unboundedData.forEach((unbound) => {
                // 检查 unbound 是否在已有绑定的 employee 数据中
                const isBound = data.some(
                    (employee) => employee.assistant.id === unbound.assistant_id
                );
                if (!isBound) {
                    result.push({
                        assistant: "",
                        wechats: [
                            {
                                wx_id: unbound.wx_id,
                                nickname: unbound.nickname,
                                auto_reply: unbound.auto_reply,
                                is_online: unbound.is_online,
                                head_url: unbound.head_url,
                            },
                        ],
                        id: `unattached-${unbound.wx_id}`,
                    });
                }
            });
        }

        return result;
    };

    const calculateCurrentWxAccounts = () => {
        // 包含所有微信账号(在线和离线)的数量
        return employeeList.filter(item => item.wx_id).length;
    };

    // 获取员工及微信账号数据
    const getAssistantsWxIds = useCallback(async () => {
        // 判断是否有现有账号的 updated_at 变化
        const updatedAtChanged = employeeList.some((account) => {
            const previous = previousUpdatedAt[account.wx_id];
            return previous && previous !== account.updated_at;
        });

        if (updatedAtChanged) {
            // 登录成功，已有账号的 updated_at 发生变化
            setIntervalActive(false);
            setPreviousUpdatedAt(
                employeeList.reduce((acc, account) => {
                    acc[account.wx_id] = account.updated_at;
                    return acc;
                }, {})
            );
        }

        try {
            const {data: assistantData} = await getAssistantsWithWeChatApi(); // 调用新接口获取数据
            const {data: unboundedData} = await getWeChats();

            const processedData = processEmployeeData(assistantData, unboundedData); // 处理数据
            // console.log(processedData);

            // 初始化所有回复相关的状态
            const newGlobalSwitchChecked = {};
            const newPrivateSwitchChecked = {};
            const newGroupSwitchChecked = {};
            const newNewChatSwitchChecked = {};

            if (assistantData) {
                // 处理已绑定助手的微信账号
                assistantData.forEach((assistant) => {
                    assistant.wechats.forEach((wechat) => {
                        if (wechat.wx_id) {
                            newGlobalSwitchChecked[wechat.wx_id] = wechat.auto_reply || false;
                            newPrivateSwitchChecked[wechat.wx_id] =
                                wechat.private_auto_reply || false;
                            newGroupSwitchChecked[wechat.wx_id] =
                                wechat.group_auto_reply || false;
                            newNewChatSwitchChecked[wechat.wx_id] =
                                wechat.new_auto_reply || false;
                        }
                    });
                });

                // 处理未绑定助手的微信账号
                unboundedData.forEach((account) => {
                    if (account.wx_id) {
                        newGlobalSwitchChecked[account.wx_id] = account.auto_reply || false;
                        newPrivateSwitchChecked[account.wx_id] =
                            account.private_auto_reply || false;
                        newGroupSwitchChecked[account.wx_id] =
                            account.group_auto_reply || false;
                        newNewChatSwitchChecked[account.wx_id] =
                            account.new_auto_reply || false;
                    }
                });

                // 更新所有开关状态
                setGlobalSwitchChecked(newGlobalSwitchChecked);
                setPrivateSwitchChecked(newPrivateSwitchChecked);
                setGroupSwitchChecked(newGroupSwitchChecked);
                setNewChatSwitchChecked(newNewChatSwitchChecked);
            }
            setEmployeeList(processedData);
        } catch (error) {
            Message.error("获取员工及微信账号信息失败");
            console.log(error);
        }
    }, [employeeList, previousUpdatedAt]);

    useEffect(() => {
        getAssistantsWxIds(); // 获取数据
        // eslint-disable-next-line
    }, []);

    // 设置轮询
    useEffect(() => {
        let intervalId;
        if (intervalActive) {
            intervalId = setInterval(async () => {
                await getAssistantsWxIds();
            }, 5000); // 将轮询间隔设置为5秒
        }

        return () => clearInterval(intervalId);
    }, [intervalActive, getAssistantsWxIds]);

    // 更新自动回复开关
    const handleReplyConfigChange = async (
        wxID,
        autoMode,
        switchChecked,
        newAutoReplyStatus,
        config = null
    ) => {
        // 动态生成请求数据
        const back_data = {
            wx_id: wxID,
        };

        // 根据模式更新对应字段
        switch (autoMode) {
            case "global":
                back_data.auto_reply = switchChecked;
                setGlobalSwitchChecked((prev) => ({
                    ...prev,
                    [wxID]: switchChecked,
                }));
                break;

            case "private":
                back_data.private_auto_reply = switchChecked;
                setPrivateSwitchChecked((prev) => ({
                    ...prev,
                    [wxID]: switchChecked,
                }));
                break;

            case "group":
                back_data.group_auto_reply = switchChecked;
                if (config !== null) {
                    back_data.group_config = config; // 将数字配置传递给后端
                }
                setGroupSwitchChecked((prev) => ({
                    ...prev,
                    [wxID]: switchChecked,
                }));
                break;

            case "new_chat":
                back_data.new_auto_reply = switchChecked;
                if (config !== null) {
                    back_data.new_config = config; // 将数字配置传递给后端
                }
                setNewChatSwitchChecked((prev) => ({
                    ...prev,
                    [wxID]: switchChecked,
                }));
                break;

            default:
                console.error("无效的 autoMode:", autoMode);
                return;
        }

        try {
            // 调用 API 更新后端数据
            const res = await updateWechatAutoReplyApi(back_data);

            if (res.status === "success" || res.status === 200) {
                await getAssistantsWxIds(); // 更新本地数据
                Message.success("自动回复状态更新成功");
            } else {
                Message.error("自动回复状态更新失败");
            }

            // 更新本地状态
            setEmployeeList((prev) =>
                prev.map((item) =>
                    item.wx_id === wxID
                        ? {...item, auto_reply: newAutoReplyStatus}
                        : item
                )
            );
        } catch (error) {
            Message.error("自动回复状态更新失败");
            console.error(error);
        }
    };
    // 选择员工，点击“编辑”按钮时调用
    const handleSelectedEmployee = (row) => {
        // setSelectedEmployee(row); // 传递选中的员工数据

        dispatch(setSelectedEmployee(row));
        navigate(`/employees/${row.id}`); // 跳转到编辑路由，并传递员工ID
    };

    // 添加员工
    const handleAddEmployee = async () => {
        const remainingSeats = maxEmployeeSeats - employeeList.length; // 剩余席位
        if (remainingSeats > 0) {
            const newEmployee = {
                name: `新员工`,
                instructions: "你是一个智能助手，在微信上陪好客户",
                draft_instructions: "你是一个智能助手，在微信上陪好客户",
                sales_process: "{}",
                knowledge_tables: "{}"
            };

            try {
                await addAssistantApi(newEmployee).then(async (res) => {
                    if (res.status === "success") {
                        const vectorStore = await openai.beta.vectorStores.create({
                            name: res.data.id,
                        });
                        // console.log(vectorStore)
                        await updateAssistantApi(res.data.id, {
                            openai_vs_id: vectorStore.id,
                        });
                        Message.success(`已添加 ${newEmployee.name}`);
                        await getAssistantsWxIds(); // 刷新员工列表
                    } else {
                        Message.error("添加员工失败，请稍后重试");
                    }
                }); // 调用添加员工 API
            } catch (error) {
                Message.error("添加员工失败，请稍后重试");
            }
        } else {
            Message.warning("已无剩余席位");
        }
    };


    // 添加处理函数
    const handleQrcodeModalShow = () => {
        setIsQrcodeModalVisible(true);
    };

    const handleQrcodeModalClose = () => {
        setIsQrcodeModalVisible(false);
    };

    // 动态生成表格列
    const webColumns = [
        {
            title: "数字员工",
            width: 250,
            dataIndex: "name",
            key: "name",
            render: (name) => <span>{name || " "}</span>,
        },
        {
            title: "发布账号",
            dataIndex: "nickname",
            key: "nickname",
            width: 290,
            // 在 webColumns 中的 nickname 列的 render 函数中修改
            render: (nickname, row) => (
                <Space style={{width: "90%", justifyContent: "space-between"}}>
                    <Space>
                        <Avatar
                            shape='square'
                            size={32}
                            style={{
                                backgroundColor: row.is_online ? '#00B42A' : '#C9CDD4'
                            }}
                        >
                            {row.head_url ? (
                                <img
                                    alt="avatar"
                                    src={row.head_url}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                            ) : (
                                <IconWechat style={{color: '#fff'}}/>
                            )}
                        </Avatar>
                        <span
                            style={{
                                color: row.is_online ? "black" : "#C9CDD4",
                            }}
                        >
                {row.assistant === "" ? row.nickname : nickname}
            </span>
                    </Space>
                </Space>
            )
        },
        {
            title: "登录",
            dataIndex: "login",
            key: "login",
            width: 100,
            render: (_, row) => (
                !row.is_online && row.assistant !== "" && row.wx_id !== null ? (
                    <Button
                        type="outline"
                        status="warning"
                        size="mini"
                        onClick={() => handleLoginClick(row)}
                        disabled={isLoggingIn}
                    >
                        重新登录
                    </Button>
                ) : null
            ),
        },
        {
            title: "编辑",
            dataIndex: "edit",
            width: 80,
            key: "edit",
            render: (_, row) => (
                <Button
                    style={{marginLeft: "-15px"}}
                    type="primary"
                    disabled={row.assistant === ""}
                    onClick={() => handleSelectedEmployee(row)}
                >
                    编辑
                </Button>
            ),
        },
        {
            title: "总开关",
            dataIndex: "auto_reply",
            key: "auto_reply",
            width: 100,
            render: (autoReply, record) => (
                <Space>
                    {!record.wx_id || !record.is_online ? (
                        ""
                    ) : (
                        <Switch
                            checked={globalSwitchChecked[record.wx_id] ?? autoReply}
                            disabled={!record.wx_id || !record.is_online}
                            onChange={(checked) =>
                                handleReplyConfigChange(
                                    record.wx_id,
                                    "global",
                                    checked,
                                    autoReply
                                )
                            }
                        />
                    )}
                </Space>
            ),
        },
        {
            title: "私聊回复",
            dataIndex: "private_auto_reply",
            key: "private_auto_reply",
            width: 100,
            render: (privateReply, record) => (
                <Space>
                    {!record.wx_id || !record.is_online ? (
                        ""
                    ) : (
                        <Switch
                            checked={privateSwitchChecked[record.wx_id] ?? privateReply}
                            disabled={!record.wx_id || !record.is_online}
                            onChange={(checked) =>
                                handleReplyConfigChange(
                                    record.wx_id,
                                    "private",
                                    checked,
                                    privateReply
                                )
                            }
                        />
                    )}
                </Space>
            ),
        },
        {
            title: "群聊回复",
            dataIndex: "group_auto_reply",
            key: "group_auto_reply",
            width: 200,
            render: (groupReply, record) => (
                <Space
                    style={{
                        width: "100%",
                        // , justifyContent: 'space-between'
                    }}
                >
                    {!record.wx_id || !record.is_online ? (
                        ""
                    ) : (
                        <Space>
                            <Switch
                                checked={groupSwitchChecked[record.wx_id] ?? groupReply}
                                disabled={!record.wx_id || !record.is_online}
                                onChange={(checked) =>
                                    handleReplyConfigChange(
                                        record.wx_id,
                                        "group",
                                        checked,
                                        groupReply
                                    )
                                }
                            />
                            <Dropdown
                                trigger="click"
                                position="br"
                                onVisibleChange={(visible) => {
                                    setGroupDropdownOpen((prev) => ({
                                        ...prev,
                                        [record.wx_id]: visible,
                                    }));
                                }}
                                droplist={
                                    <Menu
                                        onClickMenuItem={(key) =>
                                            handleReplyConfigChange(
                                                record.wx_id,
                                                "group",
                                                groupSwitchChecked[record.wx_id],
                                                null,
                                                parseInt(key, 10)
                                            )
                                        }
                                    >
                                        <Menu.Item key="1">全部都回复</Menu.Item>
                                        <Menu.Item key="2">被@才回复</Menu.Item>
                                        <Menu.Item key="3">被@后总回</Menu.Item>
                                    </Menu>
                                }
                            >
                                <Space size={0}>
                                    <Button
                                        type="outline"
                                        style={{borderRadius: "2px 0 0 2px"}}
                                    >
                    <span>
                      {GROUP_CONFIG_MAP[record.group_config] || "全部都回复"}
                    </span>
                                    </Button>
                                    <Button
                                        type="primary"
                                        style={{
                                            width: "30px",
                                            borderRadius: "0 2px 2px 0",
                                            marginLeft: "-1px",
                                        }}
                                        icon={
                                            groupDropdownOpen[record.wx_id] ? (
                                                <IconUp/>
                                            ) : (
                                                <IconDown/>
                                            )
                                        }
                                    />
                                </Space>
                            </Dropdown>
                        </Space>
                    )}
                </Space>
            ),
        },
        {
            title: "新聊天自动回复",
            dataIndex: "new_auto_reply",
            key: "new_auto_reply",
            width: 200,
            render: (newChatAutoReply, record) => (
                <Space style={{width: "100%"}}>
                    {!record.wx_id || !record.is_online ? (
                        ""
                    ) : (
                        <Space>
                            <Switch
                                checked={newChatSwitchChecked[record.wx_id] ?? newChatAutoReply}
                                disabled={!record.wx_id || !record.is_online}
                                onChange={(checked) =>
                                    handleReplyConfigChange(
                                        record.wx_id,
                                        "new_chat",
                                        checked,
                                        newChatAutoReply
                                    )
                                }
                            />
                            <Dropdown
                                trigger="click"
                                position="br"
                                onVisibleChange={(visible) => {
                                    setNewChatDropdownOpen((prev) => ({
                                        ...prev,
                                        [record.wx_id]: visible,
                                    }));
                                }}
                                droplist={
                                    <Menu
                                        onClickMenuItem={(key) =>
                                            handleReplyConfigChange(
                                                record.wx_id,
                                                "new_chat",
                                                newChatSwitchChecked[record.wx_id] ?? newChatAutoReply,
                                                null,
                                                parseInt(key, 10)
                                            )
                                        }
                                    >
                                        <Menu.Item key="1">全部都回复</Menu.Item>
                                        <Menu.Item key="2">仅私聊回复</Menu.Item>
                                        <Menu.Item key="3">仅群聊回复</Menu.Item>
                                    </Menu>
                                }
                            >
                                <Space size={0}>
                                    <Button
                                        type="outline"
                                        style={{borderRadius: "2px 0 0 2px"}}
                                    >
                    <span>
                      {NEW_CHAT_CONFIG_MAP[record.new_config] || "全部都回复"}
                    </span>
                                    </Button>
                                    <Button
                                        type="primary"
                                        style={{
                                            width: "30px",
                                            borderRadius: "0 2px 2px 0",
                                            marginLeft: "-1px",
                                        }}
                                        icon={
                                            newChatDropdownOpen[record.wx_id] ? (
                                                <IconUp/>
                                            ) : (
                                                <IconDown/>
                                            )
                                        }
                                    />
                                </Space>
                            </Dropdown>
                        </Space>
                    )}
                </Space>
            ),
        },
        {
            title: "操作",
            dataIndex: "operation",
            key: "operation",
            width: 80,
            render: (_, row) =>
                !row.wx_id && !row.is_online ? (
                    <Button
                        type="text"
                        status="danger"
                        style={{marginLeft: "-15px"}}
                        disabled={row.assistant === ""}
                        onClick={() => handleDeleteAssistant(row.id)} // 调用删除函数
                    >
                        删除
                    </Button>
                ) : (
                    ""
                ),
        },
    ];

    const mobileColumns = [
        {
            title: "数字员工",
            width: 200,
            dataIndex: "name",
            key: "name",
            render: (name) => <span>{name || " "}</span>,
        },
        {
            title: "发布账号",
            dataIndex: "nickname",
            key: "nickname",
            width: 350,
            render: (nickname, row) => (
                <Space style={{width: "90%", justifyContent: "space-between"}}>
                    <Space>
                        <IconWechat
                            style={{
                                color:
                                    row.is_online || row?.wechats[0]?.is_online
                                        ? "#00B42A"
                                        : "#C9CDD4",
                            }}
                        />
                        <span
                            style={{
                                color:
                                    row.is_online || row?.wechats[0]?.is_online
                                        ? "black"
                                        : "#C9CDD4",
                            }}
                        >
              {row.assistant === "" ? row.wechats[0].nickname : nickname}
            </span>
                    </Space>
                </Space>
            ),
        },
        {
            title: "总开关",
            dataIndex: "auto_reply",
            key: "auto_reply",
            width: 100,
            render: (autoReply, record) => (
                <Space>
                    {!record.wx_id || !record.is_online ? (
                        ""
                    ) : (
                        <Switch
                            checked={globalSwitchChecked[record.wx_id] ?? autoReply}
                            disabled={!record.wx_id || !record.is_online}
                            onChange={(checked) =>
                                handleReplyConfigChange(
                                    record.wx_id,
                                    "global",
                                    checked,
                                    autoReply
                                )
                            }
                        />
                    )}
                </Space>
            ),
        },
    ];

    const webEmployeeTable = (
        <div style={{marginRight: 10}}>
            <Space align={"center"}>
                <h3 style={{marginTop: 16}}>
                    员工列表 {employeeList.length}/{maxEmployeeSeats}{" "}
                </h3>
                <Button
                    type="primary"
                    icon={<IconPlus/>}
                    onClick={handleAddEmployee}
                    size={"mini"}
                    disabled={employeeList.length >= maxEmployeeSeats}
                />
                <Button
                    type="text"
                    onClick={() =>
                        window.open(
                            "https://kwvm1bmowpo.feishu.cn/docx/JxridEWSYoYEPrxoDSwcz91infj?from=from_copylink",
                            "_blank"
                        )
                    }
                >
                    使用文档
                </Button>
                <Button
                    type="text"
                    onClick={handleQrcodeModalShow}
                >
                    手机监控
                </Button>
                <Button
                    type="primary"
                    onClick={handleLoginModal}
                    disabled={calculateCurrentWxAccounts() >= maxClientSeats}
                >
                    登录新微信 {calculateCurrentWxAccounts()}/{maxClientSeats}
                </Button>
                <Modal
                    title="扫码关注公众号"
                    visible={isQrcodeModalVisible}
                    onCancel={handleQrcodeModalClose}
                    footer={null}
                    style={{width: 400}}
                >
                    <div style={{textAlign: 'center', padding: '20px'}}>
                        <p style={{marginBottom: '20px', fontSize: '16px'}}>关注后点击下方登录可手机查看系统消息</p>
                        <img
                            src="https://picx.zhimg.com/70/v2-fd4efb61b5bb0c2cb24a66a80c16d424_1440w.avis?source=172ae18b&biz_tag=Post"
                            alt="公众号二维码"
                            style={{width: 200, height: 200}}
                        />
                    </div>
                </Modal>
                <WechatLogin
                    visible={isLoginModalVisible}
                    onClose={() => {
                        setIsLoginModalVisible(false);
                        setCurrentLoginAccount(null);
                    }}
                    onSuccess={handleLoginSuccess}
                    currentAccount={currentLoginAccount}
                />
            </Space>
            <Table
                columns={webColumns}
                data={employeeList}
                rowKey={(record) => record.wx_id || record.id} // 如果没有微信账号，使用员工 ID 作为 key
                pagination={false}
            />
        </div>
    );

    const mobileEmployeeTable = (
        <div style={{margin: 10, height: "90vh"}}>
            <h2>
                员工列表 {employeeList.length}/{maxEmployeeSeats}
            </h2>
            <Table
                columns={mobileColumns}
                data={employeeList}
                rowKey={(record) => record.wx_id || record.id}
                pagination={false}
            />
        </div>
    );

    return isMobile ? mobileEmployeeTable : webEmployeeTable;
};

export default EmployeeTable;
