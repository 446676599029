import React, {useEffect, useRef, useState} from 'react';
import {Button, Input, Message, Spin, Tooltip, Upload, Space} from '@arco-design/web-react';
import {IconFileImage} from '@arco-design/web-react/icon';
import OpenAI from 'openai';

const openai = new OpenAI({
    baseURL: "https://baseurl.replit.app",
    apiKey: "",
    dangerouslyAllowBrowser: true,
});

const ChatInput = ({onSend, onUploadPic, onClear, threadId, disableInput = false}) => {
    const [inputValue, setInputValue] = useState("");
    const [isListening, setIsListening] = useState(false);
    const [volume, setVolume] = useState(0);
    const [file, setFile] = useState();
    const [timeLeft, setTimeLeft] = useState(20); // 倒计时秒数
    const timerRef = useRef(null); // 用于存储计时器引用

    // 音频处理工具
    const audioTools = useRef({
        audioContext: null,
        processor: null,
        mediaRecorder: null,
        audioChunks: []
    });

    // 初始化音频处理工具
    useEffect(() => {
        if (window.AudioContext) {
            const audioContext = new window.AudioContext();
            const processor = audioContext.createScriptProcessor(1024, 1, 1);
            audioTools.current = {
                audioContext,
                processor,
                mediaRecorder: null,
                audioChunks: []
            };
        }

        return () => {
            if (audioTools.current.mediaRecorder) {
                stopRecording();
            }
            if (audioTools.current.audioContext) {
                audioTools.current.audioContext.close();
            }
        };
    }, []);

    // 修改 startRecording 函数
    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            const analyser = audioTools.current.audioContext.createAnalyser();
            const microphone = audioTools.current.audioContext.createMediaStreamSource(stream);
            microphone.connect(analyser);
            analyser.connect(audioTools.current.processor);
            audioTools.current.processor.connect(audioTools.current.audioContext.destination);

            audioTools.current.mediaRecorder = new MediaRecorder(stream);
            audioTools.current.audioChunks = [];

            const dataArray = new Uint8Array(analyser.frequencyBinCount);

            const updateVolume = () => {
                analyser.getByteFrequencyData(dataArray);
                const volume = dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
                setVolume(volume);
                if (isListening) {
                    requestAnimationFrame(updateVolume);
                }
            };
            updateVolume();

            audioTools.current.mediaRecorder.ondataavailable = (event) => {
                audioTools.current.audioChunks.push(event.data);
            };

            audioTools.current.mediaRecorder.onstop = async () => {
                // 清理计时器
                if (timerRef.current) {
                    clearInterval(timerRef.current);
                    timerRef.current = null;
                }

                // 重置倒计时
                setTimeLeft(20);

                const audioBlob = new Blob(audioTools.current.audioChunks, { type: 'audio/wav' });
                audioTools.current.audioChunks = [];

                try {
                    const audioFile = new File([audioBlob], 'audio.wav', {
                        type: 'audio/wav',
                        lastModified: new Date().getTime()
                    });

                    const response = await openai.audio.transcriptions.create({
                        file: audioFile,
                        model: "whisper-1",
                    });

                    if (response.text) {
                        setInputValue(response.text);
                        handleSend(response.text);
                    }
                } catch (error) {
                    console.error('Speech recognition error:', error);
                    Message.error('语音识别失败，请重试');
                }
            };

            audioTools.current.mediaRecorder.start();
            setIsListening(true);

            // 启动20秒倒计时
            setTimeLeft(20);
            timerRef.current = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        stopRecording();
                        return 20;
                    }
                    return prevTime - 1;
                });
            }, 1000);

        } catch (error) {
            console.error('Error accessing microphone:', error);
            Message.error('无法访问麦克风，请检查权限设置');
        }
    };


    const stopRecording = () => {
        if (audioTools.current.mediaRecorder && audioTools.current.mediaRecorder.state === 'recording') {
            audioTools.current.mediaRecorder.stop();
            setIsListening(false);

            // 清理计时器
            if (timerRef.current) {
                clearInterval(timerRef.current);
                timerRef.current = null;
            }

            // 重置倒计时
            setTimeLeft(20);
        }
    };

    // 在组件卸载时清理计时器
    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
            if (audioTools.current.mediaRecorder) {
                stopRecording();
            }
            if (audioTools.current.audioContext) {
                audioTools.current.audioContext.close();
            }
        };
    }, []);

    const toggleListening = () => {
        if (isListening) {
            stopRecording();
        } else {
            startRecording();
        }
    };


    const handleSend = (text = inputValue) => {
        // 移除输入内容检查，允许空输入触发发送
        onSend(text);
        setInputValue('');
    };

    const handleUpload = (_, currentFile) => {
        setFile(currentFile);
        onUploadPic(currentFile);
    };

    return (
        <Space style={{marginTop: "2vh", paddingLeft: 4}}>
            <Tooltip content="清空对话记录">
                <Button
                    type="text"
                    shape="circle"
                    onClick={onClear}
                    icon={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                        >
                            <path
                                d="M2 6h4V2.333C6 1.597 6.597 1 7.334 1h1.333C9.403 1 10 1.597 10 2.333V6h4c.737 0 1.334.597 1.334 1.333V14c0 .736-.597 1.333-1.334 1.333H2A1.333 1.333 0 0 1 .667 14V7.333C.667 6.597 1.264 6 2 6Zm5.334-3.667v4a1 1 0 0 1-1 1H2v1.334h12V7.333H9.667a1 1 0 0 1-1-1v-4H7.334ZM2 10v4h2.667v-1.667a.667.667 0 0 1 1.333 0V14h1.334v-2a.667.667 0 1 1 1.333 0v2H10v-1.667a.667.667 0 0 1 1.334 0V14H14v-4H2Z"/>
                        </svg>
                    }
                />
            </Tooltip>

            <Input
                style={{
                    height: "5vh",
                    width: "calc(30vw - 100px)",
                    marginLeft: 4,
                }}
                disabled={disableInput}
                value={inputValue}
                onChange={setInputValue}
                onPressEnter={() => handleSend()}
                placeholder={isListening ? "正在聆听..." : "在此输入内容，回车发送"}
                prefix={isListening && (
                    <Spin size="small" style={{marginRight: 8}}/>
                )}
                suffix={
                    <Upload
                        showUploadList={false}
                        fileList={file ? [file] : []}
                        multiple={false}
                        onChange={handleUpload}
                        accept=".png,.jpeg,.jpg"
                    >
                        <Button
                            type="text"
                            size="mini"
                            icon={<IconFileImage/>}
                            style={{color: "black"}}
                        />
                    </Upload>
                }
            />

            <Tooltip content={isListening ? `录音中...${timeLeft}秒` : "语音输入"}>
                <Button
                    type="text"
                    icon={
                        isListening ? (
                            <div style={{position: 'relative'}}>
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                     style={{
                                         color: '#f53f3f',
                                         transform: `scale(${1 + volume / 100})`,
                                         transition: 'transform 0.1s ease'
                                     }}>
                                    <path
                                        d="M12 15c1.66 0 3-1.34 3-3V6c0-1.66-1.34-3-3-3S9 4.34 9 6v6c0 1.66 1.34 3 3 3z"
                                        fill="currentColor"/>
                                    <path
                                        d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z"
                                        fill="currentColor"/>
                                </svg>
                            </div>
                        ) : (
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 15c1.66 0 3-1.34 3-3V6c0-1.66-1.34-3-3-3S9 4.34 9 6v6c0 1.66 1.34 3 3 3z"
                                      fill="currentColor"/>
                                <path
                                    d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z"
                                    fill="currentColor"/>
                            </svg>
                        )
                    }
                    style={{
                        marginTop: 3,
                        color: isListening ? '#f53f3f' : 'inherit'
                    }}
                    onClick={toggleListening}
                />
            </Tooltip>
        </Space>
    );
};

export default ChatInput;