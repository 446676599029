// src/pages/ShareMoments/Moments.js

import React from 'react';

const Moments = () => {
    const larkURL = 'https://kwvm1bmowpo.feishu.cn/base/MW4NbM4s8audK9smS9ocOlDRnJb?from=from_copylink';

    return (
        <div className="moments-container">
            <iframe
                src={larkURL}
                style={{
                    width: '100%',
                    height: '800px',
                    border: 'none',
                    marginTop: '-70px',
                }}
                title="Feishu Document"
                allow="fullscreen"
            />
        </div>
    );
};

export default Moments;