import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, Checkbox, Message, Modal, Space, Tag, Switch, Avatar,} from "@arco-design/web-react";
import "@arco-design/web-react/dist/css/arco.css";
import {
    bindAgentApi,
    checkWechatLoginApi,
    getAssistantsWithWeChatApi, getUserData, getWechatQRCodeApi,
    getWeChats,
    unBindAgentApi,
    updateAssistantApi,
    updateWechatAutoReplyApi, updateWeChatsWxId,
} from "../../../../../api/normalApi";
import {QRCodeSVG} from "qrcode.react";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../../../../store/slices/userSlice";

const statusColorMap = {
    未登录: "blue",
    登录中: "orange",
    需要登录: "#FF7D00",
    准备就绪: "green",
    已绑定: "blue",
};

const LaunchModal = ({selectedEmployee, draftInstructions, maxSeats}) => {
    // 状态声明
    const [visible, setVisible] = useState(false);
    const [userData, setUserData] = useState({});
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [intervalActive, setIntervalActive] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [qrCode, setQrCode] = useState(null);
    const [accountCount, setAccountCount] = useState(1); // 用于生成唯一的账户 ID
    const [isLoggingIn, setIsLoggingIn] = useState(false); // 标识是否有登录正在进行
    const [currentLoggingAccountId, setCurrentLoggingAccountId] = useState(null); // 当前登录的账户 ID
    const [previousAccountCount, setPreviousAccountCount] = useState(0);
    const [previousUpdatedAt, setPreviousUpdatedAt] = useState({}); // 存储每个账号的 updated_at
    const [wxInstanceName, setWxInstanceName] = useState(); //登陆新的微信账户返回的实例
    const [privateSwitchChecked, setPrivateSwitchChecked] = useState(true);
    const [globalSwitchChecked, setGlobalSwitchChecked] = useState(false);
    const [countdown, setCountdown] = useState(180); // 180秒倒计时
    const [appid, setAppid] = useState("");
    const regionId = useRef('440000'); // 使用useRef存储地区ID，默认广东省
    const salesMode = useRef(0);
    const remainingSeats = maxSeats - accounts.length;
    const user = useSelector(selectCurrentUser); // 使用选择器获取 currentUser

    // IPv6前缀到地区的映射关系
    const IPV6_PREFIX_MAP = {
        // 电信IPv6前缀映射 (240e)
        '240e:0:': '110000',    // 北京
        '240e:1:': '310000',    // 上海
        '240e:2:': '440000',    // 广东
        '240e:3:': '320000',    // 江苏
        '240e:4:': '330000',    // 浙江
        '240e:5:': '370000',    // 山东
        '240e:6:': '420000',    // 湖北
        '240e:7:': '430000',    // 湖南
        '240e:8:': '350000',    // 福建
        '240e:9:': '500000',    // 重庆
        '240e:10:': '510000',   // 四川

        // 联通IPv6前缀映射 (2408)
        '2408:8000:': '110000', // 北京
        '2408:8200:': '310000', // 上海
        '2408:8400:': '440000', // 广东
        '2408:8600:': '320000', // 江苏
        '2408:8800:': '330000', // 浙江
        '2408:8a00:': '370000', // 山东
        '2408:8c00:': '420000', // 湖北

        // 移动IPv6前缀映射 (2409)
        '2409:8000:': '110000', // 北京
        '2409:8200:': '310000', // 上海
        '2409:8400:': '440000', // 广东
        '2409:8600:': '320000', // 江苏
        '2409:8800:': '330000', // 浙江
        '2409:8a00:': '370000', // 山东
        '2409:8c00:': '420000'  // 湖北
    };

    // 获取IPv6地址对应的地区ID
    const getRegionIdFromIP = (ip) => {
        // 默认返回广东省作为fallback
        const defaultRegionId = '440000';

        if (!ip) return defaultRegionId;

        // 如果是IPv6地址
        if (ip.includes(':')) {
            // 提取IPv6地址的前缀部分
            const ipPrefix = ip.toLowerCase();

            // 遍历所有前缀映射
            for (const [prefix, regionId] of Object.entries(IPV6_PREFIX_MAP)) {
                if (ipPrefix.startsWith(prefix)) {
                    return regionId;
                }
            }

            // 如果没有具体匹配，使用运营商级别的默认匹配
            if (ipPrefix.startsWith('240e')) {       // 电信
                return '440000';  // 默认广东省
            } else if (ipPrefix.startsWith('2408')) { // 联通
                return '110000';  // 默认北京市
            } else if (ipPrefix.startsWith('2409')) { // 移动
                return '310000';  // 默认上海市
            }
        }

        return defaultRegionId;
    };

    // IP查询服务列表
    const IP_SERVICES = [
        { url: 'https://api.ipify.org?format=json', key: 'ip' },
        { url: 'https://api.ip.sb/ip', transform: (data) => data.trim() },
        { url: 'https://api64.ipify.org?format=json', key: 'ip' },
        { url: 'https://httpbin.org/ip', key: 'origin' }
    ];

    // 获取IP地址
    const getIpAddress = async () => {
        for (const service of IP_SERVICES) {
            try {
                const response = await fetch(service.url);
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                
                let ip;
                if (service.key) {
                    const data = await response.json();
                    ip = data[service.key];
                } else if (service.transform) {
                    const text = await response.text();
                    ip = service.transform(text);
                }

                // console.log('IP地址:', ip);
                // 获取并设置地区ID
                const newRegionId = getRegionIdFromIP(ip);
                // console.log('地区ID:', newRegionId, '地区名称:', REGION_MAP[newRegionId]);
                regionId.current = newRegionId;
                return;
            } catch (error) {
                console.warn(`IP service ${service.url} failed:`, error);
                continue; // 继续尝试下一个服务
            }
        }
        throw new Error('所有IP查询服务均失败');
    };

    // 获取所有微信账户并检测登录状态，并且匹配更新 '已绑定' 状态
    async function getAllWxAccounts() {
        let wxData = [];
        try {
            // 获取所有微信账户
            const weChatData = await getWeChats();
            wxData = weChatData.data
            // console.log(weChatData.data)
            // setAccounts(weChatData.data);

            if (weChatData && weChatData.data) {
                // 创建临时变量 tempAccounts 来存储初始账户
                let tempAccounts = [];

                // 将 fetchedAccounts 中的账户添加到 tempAccounts
                const fetchedAccounts = weChatData.data.map((account) => ({
                    ...account,
                    id: account.wx_id,
                    label: account.nickname,
                    platform: "wechat",
                    status: account.is_online === true ? "准备就绪" : "需要登录",
                    statusColor:
                        account.is_online === true
                            ? statusColorMap["准备就绪"]
                            : statusColorMap["需要登录"],
                    isNew: false, // 已存在的账户
                    updated_at: account.updated_at, // 确保API返回updated_at
                }));

                // 将准备就绪的账户和 isNew: true 的账户合并
                tempAccounts = [
                    ...fetchedAccounts,
                    ...tempAccounts.filter((a) => a.isNew), // 保留所有 isNew: true 的账户（待登录的占位账户）
                ];

                // 获取员工及微信账号信息，更新 '已绑定' 状态
                const assistantsData = await getAssistantsWithWeChatApi(); // 调用新接口获取数据
                if (assistantsData && assistantsData.data) {
                    // 遍历 tempAccounts 并更新匹配到的账户状态为 '已绑定'
                    tempAccounts = tempAccounts.map((account) => {
                        // 查找是否有 assistant.id 匹配 selectedEmployee.id
                        const matchingAssistant = assistantsData.data.find(
                            (item) => item.assistant.id === selectedEmployee.id,
                        );

                        if (
                            matchingAssistant &&
                            matchingAssistant.wechats.some((wx) => wx.wx_id === account.id)
                        ) {
                            // 如果找到匹配的账号，将状态更新为 '已绑定'
                            return {
                                ...account,
                                status: "已绑定",
                                assistant: matchingAssistant.assistant.name,
                            };
                        }

                        // 没有匹配则保持原状态
                        return account;
                    });
                }

                // 统一调用 setAccounts 更新账户状态
                setAccounts(tempAccounts);

                // 检查登录是否成功
                if (isLoggingIn && currentLoggingAccountId) {
                    const currentCount = weChatData.data.length;
                    // 判断是否有新账号添加（账号数量增加）
                    if (currentCount > previousAccountCount) {
                        // 登录成功，账号数量增加
                        setQrCode(null);
                        setIntervalActive(false);
                        setIsLoggingIn(false);
                        setCurrentLoggingAccountId(null);
                        setPreviousAccountCount(currentCount);
                        setPreviousUpdatedAt(
                            weChatData.data.reduce((acc, account) => {
                                acc[account.wx_id] = account.updated_at;
                                return acc;
                            }, {}),
                        );
                        Message.success("登录成功");
                        return;
                    }

                    // 判断是否有现有账号的 updated_at 变化
                    const updatedAtChanged = weChatData.data.some((account) => {
                        const previous = previousUpdatedAt[account.wx_id];
                        return previous && previous !== account.updated_at;
                    });

                    if (updatedAtChanged) {
                        // 登录成功，已有账号的 updated_at 发生变化
                        setQrCode(null);
                        setIntervalActive(false);
                        setIsLoggingIn(false);
                        setCurrentLoggingAccountId(null);
                        setPreviousAccountCount(currentCount);
                        setPreviousUpdatedAt(
                            weChatData.data.reduce((acc, account) => {
                                acc[account.wx_id] = account.updated_at;
                                return acc;
                            }, {}),
                        );

                        // 移除所有 isNew: true 的占位账户
                        setAccounts((prevAccounts) =>
                            prevAccounts.filter((account) => !account.isNew),
                        );

                        Message.success("登录成功");
                    }
                } else {
                    // 更新前一次的账户列表和计数
                    setPreviousAccountCount(weChatData.data.length);
                    setPreviousUpdatedAt(
                        weChatData.data.reduce((acc, account) => {
                            acc[account.wx_id] = account.updated_at;
                            return acc;
                        }, {}),
                    );
                }
                return wxData
            }
            return wxData
        } catch (err) {
            console.log("获取账户失败:", err);
            Message.error("获取账户失败，请稍后重试");
        }
        // eslint-disable-next-line
    }

    // 组件加载时获取IP地址
    useEffect(() => {
        getIpAddress(); // 组件加载时获取IP地址
    }, []);

    // 打开模态框并获取账户
    const handleOpen = useCallback(async () => {
        setVisible(true);
        await getAllWxAccounts();
        await getUserData(user.user_id).then((data) => {
            // console.log(data.data)
            setUserData(data.data);
        })
    }, [getAllWxAccounts]);

    // 关闭模态框并清理相关状态
    const handleClose = useCallback(() => {
        cancelLogin().then(r => {
            setQrCode(null); // 隐藏二维码
            setVisible(false);
            setSelectedAccounts([]);
            setIntervalActive(false); // 停止定时器
            setIsLoggingIn(false); // 重置登录状态
            setCurrentLoggingAccountId(null); // 重置当前登录账户 ID

            // 过滤掉“未登录”和“登录中”的账户
            setAccounts((prevAccounts) =>
                prevAccounts.filter((account) => account.status === "准备就绪"),
            );
            setPreviousAccountCount(0);
            setPreviousUpdatedAt({});
        });
        // eslint-disable-next-line
    }, []);

    // 处理账户选择
    const handleAccountChange = useCallback(
        async (checkedValues) => {
            // 1. Update state
            setSelectedAccounts(checkedValues);

            // 2. Identify newly checked accounts
            const newlyChecked = checkedValues.filter(
                (val) => !selectedAccounts.includes(val)
            );

            // 3. For each newly checked account, call updateWeChatsWxId
            for (const accId of newlyChecked) {
                const account = accounts.find((a) => a.id === accId);
                if (account && account.appid) {
                    try {
                        const resp = await updateWeChatsWxId(account.appid);
                        if (resp.status === "success") {
                            if (typeof resp.data === 'string' && resp.data.includes('第三方接口返回错误: 设备已离线')) {
                                const data = await getWechatQRCodeApi(account.appid, regionId.current);
                                if (data.data.ret === 500) {
                                    Message.error(data.data.msg);
                                    setIsLoggingIn(false);
                                    setCurrentLoggingAccountId(null);
                                } else {
                                    setQrCode(data.data.data.qrData);
                                    setWxInstanceName(data.data.data.uuid);
                                    setAppid(data.data.data.appId);
                                    setIntervalActive(true);

                                    // 设置当前正在登录的账号
                                    setCurrentLoggingAccountId(accId);

                                    // 更新账号状态为"登录中"
                                    setAccounts((prevAccounts) => {
                                        return prevAccounts.map((acc) => {
                                            if (acc.id === accId) {
                                                return {
                                                    ...acc,
                                                    status: "登录中",
                                                    statusColor: statusColorMap["登录中"],
                                                };
                                            }
                                            return acc;
                                        });
                                    });
                                }
                            }
                        }
                    } catch (err) {
                        console.error("Failed to update WeChats WxId:", err);
                        Message.error("更新微信 ID 失败，请稍后重试");
                    }
                }
            }
        },
        [selectedAccounts, accounts]
    );

    // 修改登录状态检查的 useEffect
    useEffect(() => {
        let intervalId;

        if (intervalActive && wxInstanceName) {
            let attempts = 0;
            const maxAttempts = 60;

            intervalId = setInterval(async () => {
                try {
                    const loginResponse = await checkWechatLoginApi(wxInstanceName, appid);
                    console.log(loginResponse)

                    if (loginResponse.data.msg !== "操作成功") {
                        clearInterval(intervalId);
                        await getAllWxAccounts();
                        // 登录成功后隐藏二维码
                        setQrCode(null);
                        setIntervalActive(false);
                        setIsLoggingIn(false);
                        setWxInstanceName(null);
                        setCurrentLoggingAccountId(null);
                        Message.success("登录成功");

                        // 更新账号状态
                        setAccounts((prevAccounts) => {
                            return prevAccounts.map((account) => {
                                if (account.id === currentLoggingAccountId) {
                                    return {
                                        ...account,
                                        status: "准备就绪",
                                        statusColor: statusColorMap["准备就绪"],
                                    };
                                }
                                return account;
                            });
                        });
                    }

                    attempts++;
                    if (attempts >= maxAttempts) {
                        clearInterval(intervalId);
                        setQrCode(null);
                        setIntervalActive(false);
                        setIsLoggingIn(false);
                        setWxInstanceName(null);
                        setCurrentLoggingAccountId(null);
                        Message.error("登录超时，请重试");
                    }
                } catch (error) {
                    clearInterval(intervalId);
                    setQrCode(null);
                    setIntervalActive(false);
                    setIsLoggingIn(false);
                    setWxInstanceName(null);
                    setCurrentLoggingAccountId(null);
                    Message.error("登录失败，请重试");
                }
            }, 3000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalActive, wxInstanceName, appid, currentLoggingAccountId, getAllWxAccounts]);

    // 登录新账户
    const addAccounts = useCallback(async () => {
        if (isLoggingIn) {
            Message.warning("登录过程中无法添加新账号");
            return;
        }

        // 检查剩余席位
        if (remainingSeats <= 0) {
            Message.error("没有剩余席位，无法添加账号");
            return;
        }

        // 开始登录流程
        setIsLoggingIn(true);
        // setCurrentLoggingAccountId(newId);

        try {
            const data = await getWechatQRCodeApi("", regionId.current);
            if (data.data.ret === 500) {
                Message.error(data.data.msg);
                setIsLoggingIn(false);
                setCurrentLoggingAccountId(null);
            } else {
                setQrCode(data.data.data.qrData);
                setWxInstanceName(data.data.data.uuid);        // 使用 uuid 作为实例名
                setAppid(data.data.data.appId);
                setIntervalActive(true); // 启动轮询
            }


        } catch (err) {
            console.log(err);
            Message.error("登录失败，请重试");
            setIsLoggingIn(false);
            setCurrentLoggingAccountId(null);
        }
        // eslint-disable-next-line
    }, [accounts.length, accountCount, isLoggingIn, remainingSeats]);

    // 检查账号是否要重新登录
    const handleLoginClick = useCallback(
        async (accountId) => {
            let accounts = await getAllWxAccounts();
            if (isLoggingIn) {
                Message.warning("当前已有账号正在登录，请等待完成");
                return;
            }

            // Find and log the corresponding account
            const selectedAccount = accounts.find(account => account.wx_id === accountId);

            // Set switch states based on selected account
            if (selectedAccount) {
                // 使用已有的配置或默认值
                setPrivateSwitchChecked(selectedAccount.private_auto_reply ?? true);
                setGlobalSwitchChecked(selectedAccount.group_auto_reply ?? false);

                // 如果是掉线的账号，直接使用其现有配置
                if (selectedAccount.status === "需要登录" ||
                    (selectedAccount.status === "已绑定" && !selectedAccount.is_online)) {
                    setPrivateSwitchChecked(selectedAccount.private_auto_reply);
                    setGlobalSwitchChecked(selectedAccount.group_auto_reply);
                }
            }

            try {
                const data = await getWechatQRCodeApi(selectedAccount.appid, regionId.current);
                if (data.data.ret === 500) {
                    if (data.data.msg === "微信已登录，请勿重复登录。") {
                        // 如果已经登录，直接更新状态
                        await updateWechatAutoReplyApi({
                            wx_id: selectedAccount.wx_id,
                            is_online: true,
                            private_auto_reply: selectedAccount.private_auto_reply,  // 保持原有配置
                            group_auto_reply: selectedAccount.group_auto_reply      // 保持原有配置
                        });
                        Message.success("账号已在线");
                        await getAllWxAccounts();
                    } else {
                        Message.error(data.data.msg);
                    }
                    return;
                }

                // 如果需要登录，设置登录状态和显示二维码
                setIsLoggingIn(true);
                setCurrentLoggingAccountId(accountId);
                setQrCode(data.data.data.qrData);
                setWxInstanceName(data.data.data.uuid);
                setAppid(data.data.data.appId);
                setIntervalActive(true);

                // 更新账号状态为"登录中"
                setAccounts((prevAccounts) => {
                    return prevAccounts.map((account) => {
                        if (account.status === "登录中" && account.id !== accountId) {
                            return {
                                ...account,
                                status: "未登录",
                                statusColor: statusColorMap["未登录"],
                            };
                        } else if (account.id === accountId) {
                            return {
                                ...account,
                                status: "登录中",
                                statusColor: statusColorMap["登录中"],
                            };
                        }
                        return account;
                    });
                });

            } catch (err) {
                console.log(err);
                Message.error("登录失败，请重试");
                setQrCode(null);
                setIsLoggingIn(false);
                setCurrentLoggingAccountId(null);
            }
        },
        [isLoggingIn, getAllWxAccounts]
    );

    const cancelLogin = async () => {
        if (wxInstanceName) {
            setCountdown(180); // 重置倒计时
            setIntervalActive(false); //不用继续检测登录状态
            setQrCode(null);
            setIsLoggingIn(false);
            setCurrentLoggingAccountId(null); // 重置当前登录账户 ID
            setWxInstanceName(null); //清空现在的微信实例
        }
    };

    //解绑agent
    const unBindAgent = async (account) => {
        if (account.status === "已绑定") {
            try {
                const data = await unBindAgentApi({
                    wx_user_ids: [account.wx_id],
                    assistant_id: account.assistant_id,
                });
                if (data.status === 200) {
                    Message.success("解绑成功");
                    getAllWxAccounts();
                } else {
                    Message.error("解绑失败");
                }
            } catch (err) {
                console.log(err);
                Message.error("绑定失败");
            }
        } else {
            Message.warning("请选择需要解绑的账号");
        }
    };

    //弹出解绑确认框
    const confirmUnbind = (account) => {
        Modal.confirm({
            title: "确认解绑帐号吗？",
            content: (
                <div style={{textAlign: "center"}}>
                    <p>解绑帐号之后才能重新绑定其他助手</p>
                </div>
            ),
            onOk: async () => {
                unBindAgent(account);
            },
        });
    };

    //绑定agent
    const bindAgent = useCallback(async () => {
        // console.log(draftInstructions)
        if (selectedAccounts.length > 0) {
            try {
                let newEmployee = {
                    instructions: draftInstructions,
                    chat_mode: salesMode.current,
                };
                await updateAssistantApi(selectedEmployee.id, newEmployee).then(
                    async () => {
                        // 从selectedAccounts滤掉判断在accounts里面并且appid属性不为空的账号
                        let selectedAccountsFiltered = selectedAccounts.filter(
                            (account) =>
                                accounts.find((acc) => acc.id === account).appid !== "",
                        );

                        if (selectedAccountsFiltered.length > 0) {
                            const data = await bindAgentApi({
                                wx_user_ids: selectedAccounts,
                                assistant_id: selectedEmployee.id,
                            });
                            if (data.status === "success") {
                                Message.success("绑定成功");
                                handleClose();
                            } else {
                                Message.error("绑定失败");
                            }
                        } else {
                            Message.success("绑定成功");
                            handleClose();
                        }
                    },
                );

            } catch (err) {
                console.log(err);
                Message.error("绑定失败");
            }
        } else {
            Message.warning("请选择需要发布的账号");
        }
        // eslint-disable-next-line
    }, [selectedAccounts, selectedEmployee.id]);

    // 设置轮询
    // 首先，将倒计时逻辑独立出来
    useEffect(() => {
        let countdownId;

        if (intervalActive) {
            // 重置倒计时
            setCountdown(180);

            // 启动倒计时
            countdownId = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        clearInterval(countdownId);
                        setQrCode(null);
                        setIsLoggingIn(false);
                        setWxInstanceName(null);
                        Message.error("登录超时，请重试");
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        // 清理函数
        return () => {
            if (countdownId) {
                clearInterval(countdownId);
            }
        };
    }, [intervalActive]); // 只依赖intervalActive

    // 分离登录状态检查逻辑到另一个useEffect
    useEffect(() => {
        let intervalId;

        if (intervalActive && wxInstanceName) {
            let attempts = 0;
            const maxAttempts = 60;

            intervalId = setInterval(async () => {
                try {
                    const loginResponse = await checkWechatLoginApi(wxInstanceName, appid);

                    if (loginResponse.data.msg !== "操作成功") {
                        clearInterval(intervalId);
                        await getAllWxAccounts();
                        setIntervalActive(false);
                        setIsLoggingIn(false);
                        setQrCode(null);

                        if (loginResponse.data.msg === "检测登录失败") {
                            Message.error(loginResponse.data.msg);
                        } else {
                            Message.success(loginResponse.data.msg);
                        }
                    }

                    attempts++;
                    if (attempts >= maxAttempts) {
                        clearInterval(intervalId);
                        setIntervalActive(false);
                        setQrCode(null);
                        setIsLoggingIn(false);
                        setWxInstanceName(null);
                        Message.error("登录超时，请重试");
                    }

                    // 处理其他登录状态...
                } catch (error) {
                    clearInterval(intervalId);
                    setIntervalActive(false);
                    setQrCode(null);
                    setIsLoggingIn(false);
                    setWxInstanceName(null);
                    Message.error("登录失败，请重试");
                }
            }, 3000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalActive, wxInstanceName, appid]);

    // 组件卸载时停止定时器
    useEffect(() => {
        return () => {
            setIntervalActive(false);
        };
    }, []);

    return (
        <Space>
            <Button type="outline" onClick={handleOpen} size={"small"}>
                发布
            </Button>
            <Modal
                visible={visible}
                onCancel={handleClose}
                onOk={handleClose}
                footer={
                    <Space>
                        <Button
                            type="outline"
                            onClick={addAccounts} // '添加账号' 现在触发登录流程
                            disabled={isLoggingIn || remainingSeats <= 0} // 登录中或没有剩余席位时禁用
                        >
                            添加账号
                        </Button>
                        <Button
                            type="primary"
                            onClick={bindAgent}
                            disabled={
                                isLoggingIn ||
                                selectedAccounts.some(id => {
                                    const account = accounts.find(acc => acc.id === id);
                                    return account && (
                                        account.status === "未登录" ||
                                        account.status === "需要登录" ||
                                        account.status === "登录中" ||
                                        (account.status === "已绑定" && !account.is_online)
                                    );
                                })
                            }
                        >
                            发布更新
                        </Button>
                    </Space>
                }
                title={
                    <Space style={{width: 600, textAlign: "left"}}>
                        <h3>发布: {selectedEmployee.name}</h3>
                        <Tag color="blue">剩余帐号席位: {remainingSeats}</Tag>
                    </Space>
                }
            >
                <Checkbox.Group
                    value={selectedAccounts}
                    onChange={handleAccountChange}
                    style={{width: "100%"}}
                >
                    {accounts.map((account) => (
                        <div
                            key={account.id}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                height: 30,
                            }}
                        >
                            <Checkbox
                                value={account.id}
                                // disabled={
                                //     !(
                                //         account.status === "准备就绪" ||
                                //         account.assistant_id === selectedEmployee.id
                                //     )
                                // } // 仅允许准备就绪的账户被选择
                                style={{marginRight: "10px"}}
                            />
                            <Avatar size={25} shape={"square"}>
                                {account.head_url ? (
                                    <img alt="头像" src={account.head_url}/>
                                ) : (
                                    account.nickname.charAt(0)
                                )}
                            </Avatar>
                            <div
                                style={{
                                    flexGrow: 1,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <span style={{marginLeft: "10px"}}>{account.nickname}</span>
                                <div>
                                    {account.status === "已绑定" ? (
                                        <span style={{color: "gray", fontSize: 12}}>
                                            已绑定:{account.assistant}{" "}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {account.status === "未登录" ||
                                    account.status === "登录中" ? (
                                        <Button
                                            type="outline"
                                            size="mini"
                                            onClick={() => handleLoginClick(account.id)}
                                            disabled={isLoggingIn || account.status === "登录中"} // 禁用按钮以防止重复点击和多次登录
                                        >
                                            {account.status === "登录中" ? "等待登录" : "登录新账号"}
                                        </Button>
                                    ) : account.status === "需要登录" ||
                                    (account.status === "已绑定" &&
                                        account.is_online === false) ? (
                                        <Button
                                            type="outline"
                                            status="warning"
                                            size="mini"
                                            onClick={() => handleLoginClick(account.id)}
                                            disabled={isLoggingIn || account.status === "登录中"} // 禁用按钮以防止重复点击和多次登录
                                        >
                                            需要登录
                                        </Button>
                                    ) : account.status === "已绑定" ? (
                                        <Button
                                            type="outline"
                                            size="mini"
                                            onClick={() => {
                                                confirmUnbind(account);
                                            }}
                                        >
                                            {account.status === "已绑定"
                                                ? "解除绑定"
                                                : account.status}
                                        </Button>
                                    ) : (
                                        <Tag color={statusColorMap[account.status]} bordered>
                                            &nbsp;{account.status}&nbsp;
                                        </Tag>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </Checkbox.Group>

                {qrCode && (
                    <Space
                        direction={"vertical"}
                        style={{textAlign: "center", width: "100%"}}
                    >
                        <h3>使用实名认证/绑过卡的微信扫码登录帐号接入</h3>

                        <QRCodeSVG
                            value={qrCode}
                            size={150}
                        />
                        <div style={{marginTop: "10px", color: countdown <= 30 ? "#ff4d4f" : "#666"}}>
                            剩余时间：{countdown}秒
                        </div>
                        <Button
                            type="primary"
                            size="small"
                            onClick={cancelLogin}
                            style={{marginTop: "10px"}}
                        >
                            取消登录
                        </Button>
                        <Space style={{marginTop: "10px"}}>
                            <span>私聊</span>
                            <Switch size="large" checked={privateSwitchChecked}
                                    onChange={setPrivateSwitchChecked}/>
                            <span>群聊</span>
                            <Switch size="large" checked={globalSwitchChecked}
                                    onChange={setGlobalSwitchChecked}/>
                        </Space>
                    </Space>
                )}
            </Modal>
        </Space>
    );
};

export default LaunchModal;
