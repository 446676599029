// src/pages/MainLayout.js
import React, { useEffect, useState } from "react";
import "@arco-design/mobile-react/esm/style";
import {
  Avatar,
  Button,
  Layout,
  Menu,
  Message,
  Popover,
  Space,
  Typography,
} from "@arco-design/web-react";
import {
  IconBook,
  IconMenuFold,
  IconMenuUnfold,
  IconMessage,
  IconUserGroup,
  IconDashboard,
  IconUser, IconUserAdd, IconAt,
} from "@arco-design/web-react/icon";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCurrentUser,
  clearSelectedEmployee,
} from "../../store/slices/userSlice";
import { useResponsive } from "../../utils/useResponsive";
import { TabBar, Sticky } from "@arco-design/mobile-react";

const { Content, Sider } = Layout;

const MainLayout = ({ conversationList, setConversationId }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation(); // 获取当前路径
  const { isMobile } = useResponsive();

  // 修改 useSelector 以匹配新的 Redux state 结构
  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    dispatch(clearCurrentUser()); // 清除 Redux 中的 currentUser
    dispatch(clearSelectedEmployee()); // 清除 Redux 中的 selectedEmployee
    navigate("/login");
    Message.success("已退出登录");
  };

  const handleMenuClick = (key) => {
    if (key.startsWith("statistics/")) {
      navigate("/statistics");
      setConversationId(key.replace("statistics/", ""));
    } else {
      navigate(`/${key}`);
    }
  };

  const handleSubMenuClick = (key) => {
    navigate(`/statistics`);
  };

  const getSelectedMenuKey = () => {
    const path = location.pathname; // 使用 useLocation 获取路径
    if (path === "/" || path.startsWith("/messages")) return "messages";
    if (path.startsWith("/employees")) return "employees";
    if (path.startsWith("/userManage")) return "userManage";
    if (path.startsWith("/files")) return "files";
    if (path.startsWith("/statistics")) return "statistics";
    if (path.startsWith("/account")) return "account";
    if (path.startsWith("/group")) return "messages";
    if (path.startsWith("/addFriends")) return "addFriends";
    if (path.startsWith("/shareMoments")) return "shareMoments";
    return "employees";
  };

  // 定义桌面端布局
  const DesktopLayout = (
    <Layout style={{ height: "100vh", width: "100%" }}>
      <Sider
        collapsed={collapsed}
        onCollapse={handleCollapsed}
        collapsible
        style={{ height: "100vh",
          scrollbarWidth: "thin",
          scrollbarColor: "#EEE white",
        }}
        width={"210px"}
        trigger={
          collapsed ? (
            <Button
              style={{ marginBottom: 20 }}
              size={"small"}
              icon={<IconMenuUnfold style={{ fontSize: 20, marginTop: 3 }} />}
            />
          ) : (
            <Space style={{ marginBottom: 20 }}>
              <Popover
                position="top"
                content={
                  <Button type={"outline"} onClick={handleLogout}>
                    退出登录
                  </Button>
                }
              >
                <Space>
                  <Avatar style={{ backgroundColor: "#3370ff" }} size={30}>
                    {user?.username
                      ? user.username.charAt(0).toUpperCase()
                      : ""}
                  </Avatar>
                  <Typography.Text style={{ margin: 0, fontSize: 14 }}>
                    {user?.username || ""}
                  </Typography.Text>
                </Space>
              </Popover>
              <Button
                size={"small"}
                icon={<IconMenuFold style={{ fontSize: 20, marginTop: 3 }} />}
              />
            </Space>
          )
        }
      >
        <Space size="large" style={{ height: 48, marginTop: 7 }}>
          <img
            src={`${process.env.PUBLIC_URL}/logo.svg`}
            alt="logo"
            style={{ marginLeft: 12 }}
          />
        </Space>
        <Menu
          onClickMenuItem={handleMenuClick}
          onClickSubMenu={handleSubMenuClick}
          selectedKeys={[getSelectedMenuKey()]}
        >
          <Menu.Item key="messages">
            <IconMessage style={{ fontSize: 16 }} />
            消息列表
          </Menu.Item>
          <Menu.Item key="userManage">
            <IconUserGroup style={{ fontSize: 16 }} />
            客户管理
          </Menu.Item>
          <Menu.Item key="employees">
            <IconUser style={{ fontSize: 16 }} />
            数字员工
          </Menu.Item>
          <Menu.Item key="addFriends">
            <IconUserAdd style={{ fontSize: 16 }} />
            添加好友
          </Menu.Item>
          {/*<Menu.Item key="shareMoments">*/}
          {/*  <IconAt  style={{ fontSize: 16 }} />*/}
          {/*  发朋友圈*/}
          {/*</Menu.Item>*/}
          <Menu.Item key="files">
            <IconBook style={{ fontSize: 16 }} />
            销售资料
          </Menu.Item>
          <Menu.SubMenu
            key="statistics"
            selectable
            title={
              <>
                <IconDashboard style={{ fontSize: 16 }} />
                数据统计
              </>
            }
          >
            {conversationList?.map((item) => (
              <Menu.Item key={`statistics/${item.conversation_id}`}>
                {item.title}
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ marginLeft: 10, overflow: "auto" }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );

  const tabs = [
    {
      title: "消息列表",
      icon: <IconMessage />,
      path: "/messages",
    },
    {
      title: "员工列表",
      icon: <IconUserGroup />,
      path: "/employees",
    },
    {
      title: "账号管理",
      icon: <IconUser />,
      path: "/account",
    },
  ];

  const MobileLayout = (
    <div style={{ paddingBottom: "60px", zIndex: 100 }}>
      <Outlet />
      <Sticky position="bottom">
        <TabBar activeTab={location.pathname}>
          {tabs.map((tab, index) => (
            <TabBar.Item
              title={tab.title}
              icon={tab.icon}
              key={index}
              value={tab.path}
              onClick={() => navigate(tab.path)}
            />
          ))}
        </TabBar>
      </Sticky>
    </div>
  );

  return (
    <>
      {!isMobile && DesktopLayout}
      {isMobile && MobileLayout}
    </>
  );
};

export default MainLayout;
