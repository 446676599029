import React from 'react';
import {
    Space,
    Empty,
    Avatar,
    Button,
    Tag,
    Tooltip,
    Image,
    Card,
    Spin,
    Typography,
    Message
} from '@arco-design/web-react';
import {IconCopy, IconDelete, IconRefresh, IconFile} from '@arco-design/web-react/icon';
import {formatTimestampToDateTime} from '../../../../../utils/format';
import {getLarkTemporaryDownloadURLs} from "../../../../../api/normalApi";

const {Text} = Typography;

const ChatMessages = ({
                          messages,
                          contentRef,
                          isLoadingChat,
                          tableData,
                          handleDeleteMessage,
                          handleRefreshMessage,
                          larkImageUrls,
                          imageUrls
                      }) => {
    // 消息渲染映射
    const msgMap = {
        normal: (sentence, tableData, msg) => {
            // 增强空内容判断
            if (!sentence || sentence.trim() === "" || sentence.trim() === "。" ||
                sentence.trim() === "?" || sentence.trim() === "？" ||
                sentence.trim() === "!" || sentence.trim() === "！") {
                return null;
            }

            const commonStyle = {
                background: msg.role === "user" ? "#f7f7fa" : "#366ef4",
                color: msg.role === "user" ? "#000000" : "#ffffff",
                padding: "10px",
                borderRadius: "8px",
                display: "inline-block",
            };

            const displayText = msg.role === "user"
                ? sentence
                : sentence.replace(/[？?！!。，,；;、]+$/g, "");

            return displayText.trim() ? (
                <Text style={commonStyle}>
                    {displayText}
                </Text>
            ) : null;
        },
        file: (sentence, tableData, msg) => {
            const commonStyle = {
                background: msg.role === "user" ? "#f7f7fa" : "#366ef4",
                color: msg.role === "user" ? "#000000" : "#ffffff",
                padding: "10px",
                borderRadius: "8px",
                display: "inline-block",
            };

            const elements = [];
            let lastIndex = 0;
            let match;

            const larkFileRegex = /(https:\/\/open\.feishu\.cn\/open-apis\/drive\/v1\/medias\/([^/]+)\/download)/g;
            while ((match = larkFileRegex.exec(sentence)) !== null) {
                const fileLink = match[0];
                const fileToken = match[2];
                const linkIndex = match.index;

                const beforeText = sentence.slice(lastIndex, linkIndex);
                if (beforeText.trim()) {
                    elements.push(
                        <Text style={commonStyle} key={`text-${lastIndex}`}>
                            {beforeText}
                        </Text>
                    );
                }

                const fileName = findFileName(tableData, fileToken);

                if (isImage(fileToken, fileName, larkImageUrls)) {
                    elements.push(
                        <Image
                            key={`image-${fileToken}`}
                            src={larkImageUrls[fileToken]}
                            alt="图片文件"
                            width={300}
                            onError={(e) => {
                                console.error('Image load error:', e);
                            }}
                        />
                    );
                } else {
                    elements.push(
                        <Card
                            key={`card-${fileToken}`}
                            hoverable
                            style={{
                                width: "100%",
                                height: 50,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Space align="center">
                                <IconFile style={{fontSize: 30, color: "#8bc34a"}}/>
                                <div style={{fontSize: 16, fontWeight: "bold"}}>{fileName}</div>
                                <Button
                                    type="text"
                                    style={{color: "#165DFF"}}
                                    onClick={() => handleDownloadFile(fileToken, fileName)}
                                >
                                    下载
                                </Button>
                            </Space>
                        </Card>
                    );
                }

                lastIndex = linkIndex + fileLink.length;
            }

            const afterText = sentence.slice(lastIndex);
            if (afterText.trim()) {
                elements.push(
                    <Text style={commonStyle} key={`text-last`}>
                        {afterText}
                    </Text>
                );
            }

            return <Space direction="vertical">{elements}</Space>;
        }
    };

    const isCard = (sentence) => {
        const larkFileRegex = /(https:\/\/open\.feishu\.cn\/open-apis\/drive\/v1\/medias\/([^/]+)\/download)/g;
        larkFileRegex.lastIndex = 0;
        return larkFileRegex.test(sentence) ? "file" : "normal";
    };

    const findFileName = (tableData, targetFileToken) => {
        if (!tableData) return '未知文件名';

        let fileName = '未知文件名';
        Object.entries(tableData).forEach(([tableName, tableContent]) => {
            if (tableContent?.records) {
                tableContent.records.forEach(record => {
                    Object.entries(record).forEach(([fieldName, fieldValue]) => {
                        if (Array.isArray(fieldValue)) {
                            const attachment = fieldValue.find(item => item.file_token === targetFileToken);
                            if (attachment?.name) {
                                fileName = attachment.name;
                            }
                        }
                    });
                });
            }
        });
        return fileName;
    };

    const isImage = (fileToken, fileName, larkImageUrls) => {
        if (['.jpg', '.jpeg', '.png'].some(ext => fileName.toLowerCase().endsWith(ext))) {
            return true;
        }
        if (fileName === '未知文件名' && larkImageUrls[fileToken]) {
            const url = larkImageUrls[fileToken];
            return ['.jpg', '.jpeg', '.png'].some(ext => url.toLowerCase().includes(ext));
        }
        return false;
    };

    // 复制消息
    const handleCopy = (content) => {
        //复制当前内容
        navigator.clipboard.writeText(content);
    };

    const handleDownloadFile = async (fileToken, fileName) => {
        try {
            const res = await getLarkTemporaryDownloadURLs([fileToken]);
            const tmpUrls = res.data.download_urls;
            if (tmpUrls.length > 0) {
                const downloadUrl = tmpUrls[0].tmp_download_url;
                console.log("下载链接", downloadUrl);
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error("Error downloading file:", error);
            Message.error("下载文件时出错，请稍后再试。");
        }
    };

    const processMessageContent = (content) => {
        if (!content) return [];

        // 先按标点和换行分割
        const segments = content.split(/(?<![?？])([?？!。\n])/g);

        // 合并问号后带引号的情况
        const mergedSegments = [];
        for (let i = 0; i < segments.length; i += 2) {
            const current = segments[i];
            const punctuation = segments[i + 1];
            const next = segments[i + 2];

            if (punctuation === "?" && next?.startsWith("'")) {
                mergedSegments.push(current + "?" + next);
                i += 2; // 跳过下一个片段
            } else {
                mergedSegments.push(current + (punctuation || ""));
            }
        }

        // 过滤空内容和单独的标点
        return mergedSegments.filter(segment => {
            const trimmed = segment.trim();
            return trimmed.length > 0 &&
                !["。", "？", "?", "！", "!"].includes(trimmed);
        });
    };


    return (
        <Space
            direction="vertical"
            style={{
                width: "30vw",
                height: "82vh",
                overflowY: "auto",
                scrollbarWidth: "thin",
                scrollbarColor: "#EEE white",
            }}
            ref={contentRef}
        >
            {messages.length === 0 ? (
                <Empty
                    description="暂无聊天数据"
                    style={{
                        marginTop: "30vh",
                    }}
                />
            ) : (
                messages
                    .slice()
                    .reverse()
                    .map((msg, index) => (
                        <Space key={index} align="start">
                            <Avatar>
                                <img
                                    alt="avatar"
                                    src={
                                        msg.role === "user"
                                            ? "https://pic1.zhimg.com/70/v2-53c1bc420d925bbc55d3ebddc1f1a091_1440w.avis?source=172ae18b&biz_tag=Post"
                                            : "https://pic1.zhimg.com/70/v2-73543239e7ae941adf7d3a4d62385951_1440w.avis?source=172ae18b&biz_tag=Post"
                                    }
                                />
                            </Avatar>
                            <Space direction="vertical">
                                <Space direction="horizontal">
                                    <Text>{msg.role === "user" ? "用户" : "销售"}</Text>
                                    <Text type="secondary" style={{fontSize: "12px"}}>
                                        {formatTimestampToDateTime(msg.created_at)}
                                    </Text>
                                    <Text style={{fontSize: "12px", color: "#366ef4"}}>
                                        {msg.role === "assistant" &&
                                            index > 0 &&
                                            `${Math.abs(msg.created_at - msg.metadata.created_time)}s`}
                                    </Text>
                                    {msg.role === "user" && msg.metadata.manual && (
                                        <Tag color="red" size="small">
                                            触发人工
                                        </Tag>
                                    )}
                                </Space>
                                <Space direction="vertical">
                                    {msg.content.map((item, contentIndex) => {
                                        if (item.type === "text" && item.text && item.text.value) {
                                            return (
                                                <React.Fragment key={`${msg.id}-${contentIndex}`}>
                                                    {processMessageContent(item.text.value).map((sentence, sentenceIndex) => {
                                                        const key = isCard(sentence);
                                                        const renderComponent = msgMap[key];
                                                        if (renderComponent) {
                                                            return (
                                                                <Space
                                                                    key={`${msg.id}-${contentIndex}-${sentenceIndex}`}
                                                                    align="center"
                                                                >
                                                                    {renderComponent(sentence, tableData, msg)}
                                                                    <Space>
                                                                        {msg.role !== "user" && (
                                                                            <Button
                                                                                size="mini"
                                                                                icon={<IconCopy/>}
                                                                                onClick={() =>
                                                                                    handleCopy(
                                                                                        msg.role === "user"
                                                                                            ? sentence
                                                                                            : sentence.replace(
                                                                                                /[？?！!。，,；;、]+$/g,
                                                                                                "",
                                                                                            ),
                                                                                    )
                                                                                }
                                                                                type="text"
                                                                                style={{color: "black"}}
                                                                            />
                                                                        )}
                                                                        {sentenceIndex === 0 && (
                                                                            <Space>
                                                                                <Button
                                                                                    size="mini"
                                                                                    icon={<IconDelete/>}
                                                                                    onClick={() =>
                                                                                        handleDeleteMessage(msg)
                                                                                    }
                                                                                    type="text"
                                                                                    style={{color: "black"}}
                                                                                />
                                                                                {msg.role !== "user" && (
                                                                                    <Tooltip content="重新回答">
                                                                                        <Button
                                                                                            size="mini"
                                                                                            icon={<IconRefresh/>}
                                                                                            onClick={() =>
                                                                                                handleRefreshMessage(
                                                                                                    msg,
                                                                                                    index,
                                                                                                )
                                                                                            }
                                                                                            type="text"
                                                                                            style={{color: "black"}}
                                                                                        />
                                                                                    </Tooltip>
                                                                                )}
                                                                            </Space>
                                                                        )}
                                                                    </Space>
                                                                </Space>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </React.Fragment>
                                            );
                                        } else if (
                                            item.type === "image_file" &&
                                            imageUrls[item.image_file.file_id]
                                        ) {
                                            // 图片渲染部分保持不变
                                            return (
                                                <Space key={item.image_file.file_id}>
                                                    <Image
                                                        src={imageUrls[item.image_file.file_id]}
                                                        alt="用户图片"
                                                        width={250}
                                                        style={{height: "auto"}}
                                                    />
                                                    <Button
                                                        size="mini"
                                                        icon={<IconDelete/>}
                                                        onClick={() => handleDeleteMessage(msg)}
                                                        type="text"
                                                        style={{color: "black"}}
                                                    />
                                                </Space>
                                            );
                                        }
                                        return null;
                                    })}
                                </Space>
                            </Space>
                        </Space>
                    ))
            )}
            {isLoadingChat && <Spin dot style={{marginLeft: "50px"}}/>}
        </Space>
    );
};

export default ChatMessages;