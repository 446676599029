// ChatCheckList.jsx
import { Badge, Avatar, Space, List, Checkbox, Typography, Message, Button } from '@arco-design/web-react';
import React, { useEffect, useState } from 'react';
import { getWxChatsApi, getContactsApi } from '../../../../api/normalApi';

export const ChatCheckList = ({
                                  chatItem,
                                  setChatItem,
                                  currentWxAccount,
                                  // prompt 参数不再需要生成回复，所以可以忽略
                                  groupSendLoading,
                                  setGroupSendLoading,
                                  checkedItems,
                                  setCheckedItems,
                                  statusMap,
                                  setStatusMap,
                              }) => {
    const [chatList, setChatList] = useState([]);
    const [contactList, setContactList] = useState([]);

    const groupChats = chatList.filter(
        (chat) =>
            chat.is_group === true &&
            contactList.some((contact) => contact.to_user_id === chat.to_wx_id)
    );
    const privateChats = chatList.filter((chat) => chat.is_group === false);

    // 仅负责选中状态，不主动生成回复
    const handleCheck = (item) => {
        const isChecked = checkedItems.some(checkedItem => checkedItem.thread_id === item.thread_id);
        if (isChecked) {
            setCheckedItems(prev => prev.filter(checkedItem => checkedItem.thread_id !== item.thread_id));
            setStatusMap(prev => {
                const newStatus = { ...prev };
                delete newStatus[item.thread_id];
                return newStatus;
            });
        } else {
            setStatusMap(prev => ({ ...prev, [item.thread_id]: '待发' }));
            setCheckedItems(prev => [...prev, item]);
        }
    };

    const handleItemClick = (item, e) => {
        if (e.target.closest('.arco-checkbox')) {
            return;
        }
        setChatItem(item);
    };

    useEffect(() => {
        Promise.all([
            getWxChatsApi(currentWxAccount.wx_id, true),
            getContactsApi(currentWxAccount.wx_id),
        ]).then(([chatsRes, contactsRes]) => {
            setChatList(chatsRes.data);
            setContactList(contactsRes.contacts);
        });
    }, [currentWxAccount]);

    useEffect(() => {
        if (chatList.length > 0) {
            setChatItem(chatList[0]);
        }
        // eslint-disable-next-line
    }, [chatList]);

    const handleSelectAll = (type) => {
        if (type === 'group') {
            const allSelected = groupChats.every((chat) =>
                checkedItems.some((checkedItem) => checkedItem.nickname === chat.nickname)
            );
            setCheckedItems((prev) => {
                if (allSelected) {
                    return prev.filter((item) => !groupChats.some((chat) => chat.nickname === item.nickname));
                } else {
                    return [
                        ...prev,
                        ...groupChats.filter(
                            (chat) => !prev.some((checkedItem) => checkedItem.nickname === chat.nickname)
                        ),
                    ];
                }
            });
        } else if (type === 'private') {
            const allSelected = privateChats.every((chat) =>
                checkedItems.some((checkedItem) => checkedItem.nickname === chat.nickname)
            );
            setCheckedItems((prev) => {
                if (allSelected) {
                    return prev.filter((item) => !privateChats.some((chat) => chat.nickname === item.nickname));
                } else {
                    return [
                        ...prev,
                        ...privateChats.filter(
                            (chat) => !prev.some((checkedItem) => checkedItem.nickname === chat.nickname)
                        ),
                    ];
                }
            });
        }
    };

    const renderAvatar = (item) => {
        const avatarContent = item.head_url ? (
            <img src={item.head_url} alt={item.head_url} />
        ) : (
            item.nickname.slice(0, 1)
        );
        const status = statusMap[item.thread_id];
        if (status) {
            let badgeText = "";
            let badgeStyle = {};
            if (status === '待发') {
                badgeText = "待发";
                badgeStyle = { backgroundColor: '#faad14' };
            } else if (status === '完成') {
                badgeText = "完成";
                badgeStyle = { backgroundColor: '#52c41a' };
            } else if (status === '不发') {
                badgeText = "不发";
                badgeStyle = { backgroundColor: '#8c8c8c' };
            }
            return (
                <Badge
                    text={badgeText}
                    style={badgeStyle}
                    offset={[0, 0]}
                >
                    <Avatar shape="square">{avatarContent}</Avatar>
                </Badge>
            );
        }
        return <Avatar shape="square">{avatarContent}</Avatar>;
    };

    return (
        <Space direction="vertical" style={{ width: '28vw', height: '92vh', marginTop: 2 }}>
            <Space direction="horizontal" align="start">
                <Space direction={'vertical'}>
                    <Space align={'center'}>
                        <h3 style={{ margin: 0 }} onClick={() => handleSelectAll('group')}>
                            群聊
                        </h3>
                        <Button type={'outline'} size={'small'} onClick={() => handleSelectAll('group')}>
                            全选
                        </Button>
                    </Space>
                    <List
                        style={{
                            height: '87vh',
                            overflowY: 'auto',
                            width: '16vw',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#EEE white',
                        }}
                        dataSource={groupChats}
                        render={(item, index) => (
                            <List.Item
                                key={index}
                                style={{
                                    backgroundColor: chatItem?.id === item.id ? '#f0f0f0' : 'transparent',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s',
                                }}
                                onClick={(e) => handleItemClick(item, e)}
                                actions={[
                                    <Checkbox
                                        checked={checkedItems.some((checkedItem) => checkedItem.nickname === item.nickname)}
                                        onChange={() => handleCheck(item)}
                                    />,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={renderAvatar(item)}
                                    title={item.nickname}
                                    description={
                                        <Typography.Ellipsis ellipsis style={{ fontSize: '14px', color: '#1D2129' }} row={1}>
                                            {item.last_message}
                                        </Typography.Ellipsis>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Space>
                <Space direction={'vertical'} style={{ margin: 0 }}>
                    <Space align={'center'}>
                        <h3 style={{ margin: 0 }}>私聊</h3>
                        <Button type={'outline'} size={'small'} onClick={() => handleSelectAll('private')}>
                            全选
                        </Button>
                        <span>已选择 {checkedItems.length} 位好友</span>
                    </Space>
                    <List
                        style={{
                            height: '87vh',
                            overflowY: 'auto',
                            width: '16vw',
                            scrollbarWidth: 'thin',
                            scrollbarColor: '#EEE white',
                        }}
                        dataSource={privateChats}
                        render={(item, index) => {
                            const backgroundColor = chatItem?.id === item.id ? '#f0f0f0' : 'transparent';
                            return (
                                <List.Item
                                    key={index}
                                    style={{
                                        backgroundColor,
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s',
                                    }}
                                    onClick={(e) => handleItemClick(item, e)}
                                    actions={[
                                        <Checkbox
                                            checked={checkedItems.some((checkedItem) => checkedItem.nickname === item.nickname)}
                                            onChange={() => handleCheck(item)}
                                        />,
                                    ]}
                                >
                                    <List.Item.Meta
                                        avatar={renderAvatar(item)}
                                        title={item.nickname}
                                        description={
                                            <Typography.Ellipsis
                                                ellipsis
                                                style={{ fontSize: '14px', color: '#1D2129', width: '10vw' }}
                                                row={1}
                                            >
                                                {item.last_message}
                                            </Typography.Ellipsis>
                                        }
                                    />
                                </List.Item>
                            );
                        }}
                    />
                </Space>
            </Space>
        </Space>
    );
};