import React, {useState, useEffect, useMemo, useRef} from 'react';
import {Table, Tag, Space, Select, Avatar, Input} from '@arco-design/web-react';
import {IconSearch} from '@arco-design/web-react/icon';
import {getWeChats, getWxThreadsByTypeApi} from '../../../api/normalApi';
import {Link} from 'react-router-dom';

const CustomerTable = () => {
    const [weChats, setWeChats] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showAllData, setShowAllData] = useState(localStorage.getItem('userManage-showAllData') !== 'false');
    const [chatOption, setChatOption] = useState(localStorage.getItem('userManage-chatOption') || '全部');
    const wxNameMap = useMemo(() => {
        return weChats.reduce((acc, user) => {
            acc[user.wx_id] = user.nickname;
            return acc;
        }, {});
    }, [weChats]);
    const wxUrlMap = useMemo(() => {
        return weChats.reduce((acc, user) => {
            if (user.wx_id && user.head_url) { // 确保 wx_id 和 head_url 存在
                acc[user.wx_id] = user.head_url;
            }
            return acc;
        }, {});
    }, [weChats]);
    const [customerTableData, setCustomerTableData] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
        getWeChats().then((res) => {
            if (res.status === 'success') {
                setWeChats(res.data);
                const savedUserIds = localStorage.getItem('userManage-selectedUsers');
                if (savedUserIds) {
                    const userIds = JSON.parse(savedUserIds);
                    const selectedUserObjects = userIds
                        .map((wx_id) => res.data.find((chat) => chat.wx_id === wx_id))
                        .filter(Boolean);
                    setSelectedUsers(selectedUserObjects);
                    setShowAllData(false);
                } else {
                    setSelectedUsers(res.data);
                    setShowAllData(true);
                }
            }
        });
    }, []);

    const handleWxSelect = (values) => {
        if (values.length === 0) {
            setSelectedUsers(weChats);
            setShowAllData(true);
            localStorage.removeItem('userManage-selectedUsers');
            localStorage.setItem('userManage-showAllData', 'true');
        } else {
            const selectedUserObjects = values
                .map((wx_id) => weChats.find((chat) => chat.wx_id === wx_id))
                .filter(Boolean);

            setSelectedUsers(selectedUserObjects);
            setShowAllData(false);
            localStorage.setItem('userManage-selectedUsers', JSON.stringify(values));
            localStorage.setItem('userManage-showAllData', 'false');
        }
    };

    useEffect(() => {
        if (selectedUsers.length > 0) {
            let is_group = chatOption === "全部" ? '' : chatOption === "群聊";
            getWxThreadsByTypeApi(selectedUsers.map(user => user.wx_id), '', '', is_group).then((res) => {
                if (res.data) {
                    const formattedData = res.data.map(item => ({
                        key: item.id,
                        wx_nickname: wxNameMap[item.wx_id] || '',
                        my_wx_url: wxUrlMap[item.head_url] || '',
                        nickname: item.nickname,
                        intentionLevel: getIntentionLevel(item.intent_level),
                        salesProgress: item.sales_progress || '',
                        createTime: new Date(item.created_at).toLocaleDateString(),
                        wx_id: item.wx_id,
                        to_wx_id: item.to_wx_id,
                        lastFollowTime: item.updated_at && item.updated_at !== "0001-01-01T00:00:00Z"
                            ? new Date(item.updated_at).toLocaleString()
                            : '',
                        remark_name: item.remark_name,
                        head_url: item.head_url,
                    }));
                    setCustomerTableData(formattedData);
                }
            });
        }
        // eslint-disable-next-line
    }, [selectedUsers, wxNameMap, chatOption]);

    const getIntentionLevel = (level) => {
        switch (level) {
            case 1:
                return '高意向';
            case 2:
                return '中意向';
            case 3:
                return '低意向';
            case 0:
            default:
                return '无意向';
        }
    };

    // 定义表格列配置
    const columns = [
        {
            title: '接待微信',
            dataIndex: 'wx_nickname',
            render: (text, record) => (
                <Space>
                    <Avatar
                        shape="square"
                        size={33}
                        style={{backgroundColor: "#3370ff"}}
                    >
                        {wxUrlMap[record.wx_id] ? (
                            <img
                                alt="头像"
                                src={wxUrlMap[record.wx_id]} // 根据 wx_id 动态获取头像 URL
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = ""; // 替换为默认占位符图片或设置为空
                                }}
                            />
                        ) : (
                            record.wx_nickname?.charAt(0) // 显示昵称首字母作为占位
                        )}
                    </Avatar>
                    <Tag color={"green"}>{text}</Tag>
                </Space>
            ),
            width: 250,
        },
        {
            title: '客户微信',
            dataIndex: 'nickname',
            filterIcon: <IconSearch />,
            filterDropdown: ({ filterKeys, setFilterKeys, confirm }) => {
                return (
                    <div className='arco-table-custom-filter'>
                        <Input.Search
                            ref={inputRef}
                            searchButton
                            placeholder='请输入微信昵称'
                            value={filterKeys[0] || ''}
                            onChange={(value) => {
                                setFilterKeys(value ? [value] : []);
                            }}
                            onSearch={() => {
                                confirm();
                            }}
                        />
                    </div>
                );
            },
            onFilter: (value, row) => (value ? (row.nickname || '').indexOf(value) !== -1 : true),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => inputRef.current.focus(), 150);
                }
            },
            render: (text, record) => (
                <Space>
                    <Avatar
                        shape="square"
                        size={33}
                        style={{backgroundColor: "#3370ff"}}
                    >
                        {record.head_url ? (
                            <img
                                alt="头像"
                                src={record.head_url} // 使用 record.head_url 来动态渲染头像
                                onError={(e) => {
                                    e.target.onerror = null; // 防止循环触发错误事件
                                    e.target.src = ""; // 替换为默认占位符图片或设置为空
                                }}
                            />
                        ) : (
                            text?.charAt(0) // 显示昵称的第一个字符作为占位
                        )}
                    </Avatar>
                    <Link status={"green"} to={`/messages/${record.wx_id}/${record.to_wx_id}`}>
                        {record.remark_name || text}
                    </Link>
                </Space>

            ),
            width: 300,
        },
        {
            title: '意向分级',
            dataIndex: 'intentionLevel',
            width: 100,
            render: (level) => {
                const colorMap = {
                    '高意向': 'magenta',
                    '中意向': 'orange',
                    '低意向': 'blue',
                    '无意向': 'gray',
                };
                return (
                    <Tag color={colorMap[level]}>
                        {level}
                    </Tag>
                );
            },
            filters: [
                {
                    text: '无意向',
                    value: '无意向',
                },
                {
                    text: '高意向',
                    value: '高意向',
                },
                {
                    text: '中意向',
                    value: '中意向',
                },
                {
                    text: '低意向',
                    value: '低意向',
                },
            ],
            onFilter: (value, record) => record.intentionLevel === value,
        },
        {
            title: '销售进度',
            dataIndex: 'salesProgress',
            width: 220,
            render: (progress) => {
                const colorMap = {
                    '初步接洽': 'blue',
                    '需求确定': 'orange',
                    '方案报价': 'green',
                    '推进成交': 'gold',
                    '暂未成交': 'cyan',
                };
                return progress ? (
                    <Tag color={colorMap[progress]}>
                        {progress}
                    </Tag>
                ) : null;
            },
        },
        {
            title: '最近跟进时间',
            dataIndex: 'lastFollowTime',
            width: 200,
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
        },
    ];

    return (
        <Space direction={'vertical'} style={{width: '86vw'}}>
            <Space align={"start"} style={{marginTop: 12}}>
                <Select style={{width: 70}} defaultValue={chatOption} onChange={(value) => {
                    setChatOption(value);
                    localStorage.setItem('userManage-chatOption', value);
                }}>
                    <Select.Option key={"全部"} value={"全部"}>
                        {"全部"}
                    </Select.Option>
                    <Select.Option key={"私聊"} value={"私聊"}>
                        {"私聊"}
                    </Select.Option>
                    <Select.Option key={"群聊"} value={"群聊"}>
                        {"群聊"}
                    </Select.Option>
                </Select>
                <Select
                    style={{width: 1000}}
                    mode="multiple"
                    value={showAllData ? [] : selectedUsers.map(user => user.wx_id)}
                    onChange={handleWxSelect}
                    placeholder="请选择微信号（不选默认统计全部微信号）"
                    allowClear
                >
                    {weChats.map((chat) => (
                        <Select.Option key={chat.wx_id} value={chat.wx_id}>
                            <Space>
                                <Avatar
                                    shape="square"
                                    size={20}
                                    style={{backgroundColor: "#3370ff", marginBottom: 1}}
                                >
                                    {chat.head_url ? (
                                        <img alt="头像" src={`${chat.head_url}`}/>
                                    ) : (
                                        chat.nickname.charAt(0)
                                    )}
                                </Avatar>
                                {chat.nickname}
                            </Space>
                        </Select.Option>
                    ))}
                </Select>
                

            </Space>

            <Table
                columns={columns}
                data={customerTableData}
                border={false}
                style={{marginTop: 5, marginRight: 5}}
                pagination={{
                    total: customerTableData.length,
                    pageSize: 15,
                    showTotal: true,
                }}
            />
        </Space>
    );
};

export default CustomerTable;
