import React, {useEffect, useState} from 'react';
import {Table, Spin, Space, Collapse, Checkbox, Typography, Button, Message} from '@arco-design/web-react';
import {useSelector} from 'react-redux';
import {getTableData, updateAssistantApi} from '../../../../../api/normalApi';
import {useNavigate} from 'react-router-dom';
import {processLarkFiles} from "../../../../../api/openaiAPI";
import OpenAI from "openai";

const openai = new OpenAI({
    baseURL: "https://baseurl.replit.app",
    apiKey: "",
    dangerouslyAllowBrowser: true,
});


const SalesData = ({assistant, setTableData}) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.currentUser);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [checkedTables, setCheckedTables] = useState([]);
    const [tableIdMap, setTableIdMap] = useState({});
    const [expandedKeys, setExpandedKeys] = useState([]);

    const getFileExtension = (filename) => {
        if (!filename) return '';
        const ext = filename.split('.').pop().toLowerCase();
        return ext ? `.${ext}` : '';
    };

    const isSupportedFileType = (extension) => {
        const supportedExtensions = [
            '.txt', '.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xlsx',
            '.csv', '.json'
        ];
        return supportedExtensions.includes(extension?.toLowerCase());
    };

    function compareFiles(existingFiles, newFiles) {
        // 获取现有文件的ID集合
        const existingFileIds = new Set(existingFiles.map(file => file.id));

        // 获取新文件的ID集合
        const newFileIds = new Set(newFiles.map(file => file.openai_file_id));

        // 需要添加的文件: 在新文件中存在但在现有文件中不存在
        const filesToAdd = newFiles.filter(file => !existingFileIds.has(file.openai_file_id));

        // 需要删除的文件: 在现有文件中存在但在新文件中不存在
        const filesToRemove = existingFiles.filter(file => !newFileIds.has(file.id));

        return {
            filesToAdd,
            filesToRemove
        };
    }

    // 修改后的处理逻辑，统一处理所有表格的文件
    const processAllTableFiles = async () => {
        if (!data || !assistant?.openai_vs_id || !checkedTables.length) return;
        try {
            // 收集所有勾选表格中的文件
            const allFileTokens = [];

            // 遍历所有勾选的表格收集文件
            for (const tableName of checkedTables) {
                const tableData = data[tableName];
                if (!tableData?.records) continue;

                // 从记录中提取所有文件附件
                for (const record of tableData.records) {
                    // 遍历记录中的所有字段
                    for (const [fieldName, fieldValue] of Object.entries(record)) {
                        // 检查字段值是否为数组且包含 file_token
                        if (Array.isArray(fieldValue)) {
                            for (const item of fieldValue) {
                                // 检查是否是文件对象
                                if (item && typeof item === 'object' && item.file_token) {
                                    const fileInfo = {
                                        token: item.file_token,
                                        name: item.name || '',
                                        ext: getFileExtension(item.name),
                                        type: item.type || '',
                                        tableName,
                                        fieldName
                                    };

                                    allFileTokens.push(fileInfo);
                                }
                            }
                        }
                    }
                }
            }
            // 过滤掉图片文件和重复文件
            // 过滤掉图片文件和重复文件
            const uniqueFileTokens = allFileTokens.filter((f, index, self) => {
                const isUnique = index === self.findIndex(t => t.token === f.token);
                const isSupported = isSupportedFileType(f.ext);

                // // 添加调试日志，看看每个文件的具体情况
                // console.log('Processing file:', {
                //     name: f.name,
                //     ext: f.ext,
                //     isUnique,
                //     isSupported,
                //     type: f.type
                // });

                // 只需要文件是唯一的且支持的类型
                return isUnique && isSupported;
            });

            // console.log(uniqueFileTokens);
            // 如果没有新文件需要处理，直接返回
            if (uniqueFileTokens.length === 0) return;

            // 统一处理所有文件
            const result = await processLarkFiles(uniqueFileTokens.map(f => f.token));


            if (result?.result) {
                // 获取现有的向量库文件列表
                const vectorStoreFiles = await openai.beta.vectorStores.files.list(
                    assistant.openai_vs_id
                );

                // console.log(result.data, vectorStoreFiles.data);

                // 比对文件差异
                const {filesToAdd, filesToRemove} = compareFiles(
                    vectorStoreFiles.data,
                    result.data
                );
                // console.log(filesToAdd, filesToRemove)

                // 只有当真正有文件需要更新时才进行操作
                if (filesToAdd.length > 0 || filesToRemove.length > 0) {
                    // 删除不需要的文件
                    for (const file of filesToRemove) {
                        try {
                            await openai.beta.vectorStores.files.del(
                                assistant.openai_vs_id,
                                file.id
                            );
                            // console.log(`已从向量库移除文件: ${file.id}`);
                        } catch (error) {
                            console.error(`移除文件失败: ${file.name}`, error);
                        }
                    }

                    // 添加新文件
                    for (const file of filesToAdd) {
                        try {
                            await openai.beta.vectorStores.files.create(
                                assistant.openai_vs_id,
                                {file_id: file.openai_file_id}
                            );
                            console.log(`已添加文件到向量库: ${file.openai_file_id}`);
                        } catch (error) {
                            console.error(`添加文件失败: ${file.openai_file_id}`, error);
                        }
                    }
                    Message.success(
                        `文件同步完成 (添加: ${filesToAdd.length}, 删除: ${filesToRemove.length})`
                    );
                }
            }
        } catch (error) {
            console.error('处理文件失败:', error);
            Message.error('处理文件失败，请稍后重试');
        }
    };

    // 修改加载表格数据的 useEffect
    useEffect(() => {
        // 只在初始数据加载，assistant ID 变化，或选中表格变化时处理文件
        if (Object.keys(data).length > 0 && assistant?.openai_vs_id && checkedTables.length > 0) {
            processAllTableFiles();
        }
        // eslint-disable-next-line
    }, [data, checkedTables]);

    const extractFieldValue = (field) => {
        if (!field) return '';

        // 处理数组类型
        if (Array.isArray(field)) {
            // 特殊处理附件数组
            if (field[0]?.url || field[0]?.file_token) {
                return field.map(item => item.url || '').join(' ');
            }

            // 处理普通文本数组
            return field.map(item => {
                let text = '';
                if (typeof item === 'object') {
                    text = item.text || '';
                } else {
                    text = String(item || '');
                }

                // 清理标记符号并整理格式
                text = text.trim()
                    .replace(/^[∨v]\s*/i, '')  // 移除开头的 v 或 ∨ 符号
                    .replace(/^\s*[∨v]\s*/i, '')  // 移除前面有空格的 v 或 ∨ 符号
                    .trim();

                return text;
            }).filter(text => text).join('\n');  // 过滤空字符串并用换行连接
        }

        // 处理数字类型
        if (typeof field === 'number') {
            return String(field);
        }

        // 处理对象类型
        if (typeof field === 'object') {
            if (field.link) return field.link;
            if (field.text) return field.text;
            return JSON.stringify(field);
        }

        // 处理普通文本，同样清理标记符号
        let text = String(field || '');
        text = text.trim()
            .replace(/^[∨v]\s*/i, '')
            .replace(/^\s*[∨v]\s*/i, '')
            .trim();

        return text;
    };

    const formatRecordValues = (record, columns, tableName) => {
        const values = [];
        for (const columnName of columns) {
            if (columnName === 'RecordId') continue;
            const value = record[columnName];

            // 如果是内部资料表格，过滤掉附件URL
            if (tableName === '内部资料' && Array.isArray(value) && value[0]?.url) {
                continue;
            }

            const extractedValue = extractFieldValue(value);
            if (extractedValue) {
                // 添加列名作为前缀，让内容更有结构
                values.push(extractedValue.trim());
            }
        }

        // 用换行符连接不同字段的值
        return values.length > 0 ? values.join('\n') : null;
    };

    // 生成销售摘要
    const generateSalesSummary = (selectedData) => {
        if (!selectedData || Object.keys(selectedData).length === 0) {
            return '暂无选中的销售数据。';
        }

        const summaries = [];

        for (const tableName of Object.keys(selectedData)) {
            const tableData = selectedData[tableName];
            if (!tableData || !tableData.records || !tableData.columns || tableData.records.length === 0) continue;

            const fields = tableData.columns.filter(col => col !== 'RecordId');

            const dataRows = tableData.records
                .map(record => formatRecordValues(record, fields, tableName))
                .filter(row => row !== null);

            if (dataRows.length > 0) {
                summaries.push(`数据表：${tableName} 字段：${fields.join('、')} 数据如下：\n`);
                summaries.push(...dataRows);

                if (Object.keys(selectedData).indexOf(tableName) < Object.keys(selectedData).length - 1) {
                    summaries.push('');
                }
            }
        }

        return summaries.join('\n');
    };

    // 更新sales_summary
    const updateSalesSummary = async (currentCheckedTables) => {
        try {
            // 提取选中表格的数据
            const selectedData = {};
            currentCheckedTables.forEach(tableName => {
                if (data[tableName]) {
                    selectedData[tableName] = data[tableName];
                }
            });

            // 生成摘要
            const summary = generateSalesSummary(selectedData);

            // 更新到后端
            const updatedTables = currentCheckedTables.map(name => ({
                tableId: tableIdMap[name],
                tableName: name
            }));

            const newEmployee = {
                sales_summary: summary,
                knowledge_tables: JSON.stringify(updatedTables)
            };

            // 确保有变化才更新
            if (
                assistant.sales_summary === newEmployee.sales_summary &&
                assistant.knowledge_tables === newEmployee.knowledge_tables
            ) {
                return;
            }

            console.log(summary);
            await updateAssistantApi(assistant.id, newEmployee);
        } catch (error) {
            console.error('更新销售摘要失败:', error);
        }
    };

    // 初始化数据和状态的 useEffect
    useEffect(() => {
        const initializeData = async () => {
            try {
                setLoading(true);

                // 首先加载表格数据
                const res = await getTableData(user.table_url);
                if (res.data) {
                    setData(res.data);
                    setTableData(res.data);

                    // 在设置完数据后，立即处理选中状态
                    if (assistant?.knowledge_tables) {
                        const tables = JSON.parse(assistant.knowledge_tables);
                        if (!Array.isArray(tables)) {
                            console.error('knowledge_tables 不是有效的数组');
                            return;
                        }

                        const idMap = {};
                        const tableNames = tables.map(table => table.tableName).filter(Boolean);

                        tables.forEach(table => {
                            if (table.tableName) {
                                idMap[table.tableName] = table.tableId;
                            }
                        });

                        setTableIdMap(idMap);
                        setCheckedTables(tableNames);
                        setExpandedKeys(tableNames);

                        // 立即更新销售摘要，避免出现暂无数据的情况
                        const selectedData = {};
                        tableNames.forEach(tableName => {
                            if (res.data[tableName]) {
                                selectedData[tableName] = res.data[tableName];
                            }
                        });

                        const summary = generateSalesSummary(selectedData);

                        await updateAssistantApi(assistant.id, {
                            sales_summary: summary,
                            knowledge_tables: JSON.stringify(tables)
                        });
                    }
                }
            } catch (error) {
                console.error('初始化失败:', error);
                setTableIdMap({});
                setCheckedTables([]);
                setExpandedKeys([]);
            } finally {
                setLoading(false);
            }
        };

        initializeData();
        // eslint-disable-next-line
    }, [assistant, user.table_url]);

    // 修改更新摘要的 useEffect，增加判断条件，避免在初始化阶段重复执行
    useEffect(() => {
        if (!loading && Object.keys(data).length > 0 && checkedTables.length > 0) {
            // 添加延时，确保在初始化完成后再执行
            const timer = setTimeout(() => {
                updateSalesSummary(checkedTables);
            }, 0);

            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, [data, checkedTables, loading]);

    const handleCheckboxChange = (tableName, checked) => {
        // 确保checkedTables始终是数组
        const safeCheckedTables = Array.isArray(checkedTables) ? checkedTables : [];

        const currentCheckedTables = checked
            ? [...safeCheckedTables, tableName]
            : safeCheckedTables.filter(t => t !== tableName);

        setCheckedTables(currentCheckedTables);

        // 更新勾选状态时，同步更新展开状态
        if (checked) {
            setExpandedKeys(prev => Array.isArray(prev) ? [...prev, tableName] : [tableName]);
        } else {
            setExpandedKeys(prev => Array.isArray(prev) ? prev.filter(key => key !== tableName) : []);
        }
    };

    const onCollapseChange = (keys) => {
        setExpandedKeys(keys);
    };

    const generateColumns = (columns) => {
        if (!columns || columns.length === 0) return [];

        const primaryColumn = columns[0];

        return [{
            title: (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography.Text style={{marginRight: 8}}>{primaryColumn}</Typography.Text>
                    <Button
                        type="text"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/files/${assistant.id}`);
                        }}
                    >
                        编辑
                    </Button>
                </div>
            ),
            dataIndex: primaryColumn,
            ellipsis: true,
            width: '100%',
            align: 'left',
            render: (value) => {
                if (!value) return '-';
                return extractFieldValue(value);
            }
        }];
    };

    return (
        <Spin loading={loading} style={{width: '100%'}}>
            <Space direction="vertical" style={{marginTop: 5, height: '75vh', overflow: 'auto', width: '100%'}}>
                <Collapse
                    style={{width: '100%'}}
                    activeKey={expandedKeys}
                    onChange={onCollapseChange}
                    bordered={false}
                >
                    {Object.keys(data).map((tableName) => {
                        // 跳过聊天记录表格
                        if (tableName === '聊天记录') {
                            return null;
                        }

                        const list = data[tableName];
                        if (!list || !list.records) {
                            return null;
                        }

                        const columns = generateColumns(list.columns);

                        return (
                            <Collapse.Item
                                style={{width: '100%'}}
                                key={tableName}
                                name={tableName}
                                header={tableName}
                                extra={
                                    <Checkbox
                                        checked={checkedTables.includes(tableName)}
                                        onChange={(checked) => handleCheckboxChange(tableName, checked)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                }
                            >
                                <Table
                                    style={{width: '100%'}}
                                    columns={columns}
                                    data={list.records}
                                    rowKey="RecordId"
                                    pagination={{
                                        pageSize: 5,
                                        size: 'small',
                                        showTotal: true,
                                        pagePosition: 'bottomCenter'
                                    }}
                                    border={{
                                        wrapper: true,
                                        cell: true
                                    }}
                                    stripe
                                    size="small"
                                    tableLayoutFixed
                                    placeholder="-"
                                    hover
                                />
                            </Collapse.Item>
                        );
                    })}
                </Collapse>
            </Space>
        </Spin>
    );
};

export default SalesData;