import React, {
    useEffect,
    useRef,
    useState,
} from "react";
import {
    Button,
    Card,
    Image,
    Message,
    Space,
    Tag,
    Typography,
} from "@arco-design/web-react";
import {
    IconFile,
    IconWechat,
} from "@arco-design/web-react/icon";
import {
    postRunByThreadBotCloud,
} from "../../../api/openaiAPI";
import LaunchModal from "./components/ChatTest/LaunchModal";
import {
    getLarkTemporaryDownloadURLs,
} from "../../../api/normalApi";

import OpenAI from "openai";
import ChatInput from "./components/ChatTest/ChatInput";
import ChatMessages from "./components/ChatTest/ChatMessages";

const {Text} = Typography;

const openai = new OpenAI({
    baseURL: "https://baseurl.replit.app",
    apiKey: "",
    dangerouslyAllowBrowser: true,
});

const ChatTest = ({
                      threadId,
                      setThreadId,
                      draftInstructions,
                      setDraftInstructions,
                      roleInstructions,
                      tableData,
                      selectedEmployee,
                      chatMode,
                      chatBackground,
                      handleBackgroundChange,
                      maxSeats
                  }) => {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [imageUrls, setImageUrls] = useState({}); // 存储图片的URL
    const [file, setFile] = React.useState();
    const [isUserScrolling, setIsUserScrolling] = useState(false); //是否允许气泡content滑动
    const contentRef = useRef(null);
    const salesMode = useRef(0);
    const disableValue = useRef(false); //是否允许输入框输入
    const [isLoadingChat, setIsLoadingChat] = useState(false); //是否在加载对话
    const timeoutRef = useRef(null); // 添加useRef来存储定时器ID
    const [larkImageUrls, setLarkImageUrls] = useState({});
    // 存储最后一条消息
    const lastMessage = useRef(null);

    useEffect(() => {
        let newUrls = [];
        messages.forEach(msg => {
            if (msg.content && Array.isArray(msg.content) && msg.content.length > 0) {
                msg.content.forEach(contentItem => {
                    if (contentItem.type === 'text' && contentItem.text.value) {
                        // 使用带 g 标志的正则表达式
                        const regex = /(https:\/\/open\.feishu\.cn\/open-apis\/drive\/v1\/medias\/([^/]+)\/download)/g;
                        let match;
                        // 使用 while 循环获取所有匹配项
                        while ((match = regex.exec(contentItem.text.value)) !== null) {
                            const fileToken = match[2];
                            if (!newUrls.includes(fileToken)) {  // 避免重复
                                newUrls.push(fileToken);
                            }
                        }
                    }
                });
            }
        });
        if (newUrls.length > 0) {
            handleDownloadPic(newUrls)
        }
    }, [messages]);

    const handleDownloadPic = async (fileTokens) => {
        try {
            // console.log('Downloading files for tokens:', fileTokens);
            const res = await getLarkTemporaryDownloadURLs(fileTokens);
            const tmpUrls = res.data.download_urls;
            // console.log('API Response:', res.data); // 添加完整响应日志

            if (!tmpUrls || tmpUrls.length === 0) {
                console.error('No URLs returned from API');
                return;
            }

            if (tmpUrls.length !== fileTokens.length) {
                console.warn(`Expected ${fileTokens.length} URLs but got ${tmpUrls.length}`);
            }

            // 确保所有 fileToken 都有对应的 URL
            const tmpFiles = {};
            fileTokens.forEach((token, index) => {
                const matchingUrl = tmpUrls.find(url => url.file_token === token);
                if (matchingUrl) {
                    tmpFiles[token] = matchingUrl.tmp_download_url;
                } else {
                    console.warn(`No URL found for token: ${token}`);
                    // 可以选择重试这个特定的 token
                    retryDownload(token);
                }
            });

            // console.log('Processed URLs:', tmpFiles);

            setLarkImageUrls(prevUrls => {
                const newUrls = {
                    ...prevUrls,
                    ...tmpFiles
                };
                // console.log('Final image URLs state:', newUrls);
                return newUrls;
            });
        } catch (error) {
            console.error("Error downloading file:", error);
            Message.error("下载文件时出错，请稍后再试。");
        }
    };

    // 添加单个文件的重试逻辑
    const retryDownload = async (fileToken) => {
        try {
            const res = await getLarkTemporaryDownloadURLs([fileToken]);
            if (res.data.download_urls && res.data.download_urls.length > 0) {
                setLarkImageUrls(prevUrls => ({
                    ...prevUrls,
                    [fileToken]: res.data.download_urls[0].tmp_download_url
                }));
            }
        } catch (error) {
            console.error(`Retry download failed for token ${fileToken}:`, error);
        }
    };

    useEffect(() => {
        messages
            .slice()
            .reverse()
            .forEach((msg) => {
                msg.content.forEach((item, contentIndx) => {
                    if (item && item.text && item.text.value) {
                        item.text.value
                            .split(/(?<![?？])([?？!。\n])/g)
                            .reduce((acc, curr, i, arr) => {
                                // 如果当前片段后面是?，且再后面以'开头，则组合它们
                                if (
                                    i % 2 === 0 &&
                                    arr[i + 1] === "?" &&
                                    arr[i + 2]?.startsWith("'")
                                ) {
                                    acc.push(curr + "?" + arr[i + 2]);
                                    return acc;
                                }
                                // 跳过已经被组合的片段
                                if (i % 2 === 0 && arr[i - 1] === "?" && curr.startsWith("'")) {
                                    return acc;
                                }
                                // 正常添加其他片段
                                if (i % 2 === 0) {
                                    acc.push(curr + (arr[i + 1] || ""));
                                }
                                return acc;
                            }, [])
                            .filter((sentence) => sentence.trim().length > 0) // 过滤掉空句子
                    }
                });
            });
    }, [messages]);

    const scrollToBottom = () => {
        if (contentRef.current) {
            contentRef.current.scrollTo({
                top: contentRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        //先获取助手线程
        const assistantId = selectedEmployee.id;
        const localAssistantId = localStorage.getItem("assistant_id");
        if (assistantId !== localAssistantId) {
            localStorage.setItem("assistant_id", assistantId);
        }

        let thread = JSON.parse(localStorage.getItem(`${assistantId}-thread_id`));
        if (thread) {
            setThreadId(thread);
            getMessageList(thread).then(() => {
                scrollToBottom(); // 初始加载时滚动到底部
            });
        } else {
            setMessages([]);
            newThreadHandler().then((threadId) => {
                scrollToBottom(); // 新建线程后滚动到底部
            });
        }
        // eslint-disable-next-line
    }, [selectedEmployee]);

    //控制气泡出现是否滑动
    useEffect(() => {
        if (!isUserScrolling) {
            scrollToBottom();
        }
    }, [messages, isUserScrolling]);

    // 获取消息列表，增加保护机制
    const getMessageList = async (threadId) => {
        try {
            const data = await openai.beta.threads.messages.list(threadId, {
                limit: 100,
            });
            // console.log(data)
            // 增加数据校验
            if (!data?.data) {
                console.log("获取消息列表失败: 数据格式不正确");
                return []; // 返回空数组作为默认值
            }

            const messages = data.data;

            // 获取最后一条消息
            const newLastMessage = messages[0];
            // 比较最后一条消息的 ID 是否相同
            if (lastMessage.current?.id === newLastMessage?.id) {
                return [];
            }

            // 获取图片内容并生成URL
            const imagePromises = messages.map(async (msg) => {
                if (!msg || !msg.content) return;

                // 遍历消息中的内容
                const contentPromises = msg.content.map(async (item) => {
                    if (
                        item.type === "image_file" &&
                        item.image_file &&
                        item.image_file.file_id
                    ) {
                        try {
                            const res = await openai.files.content(item.image_file.file_id);
                            const imageUrl = res.url;
                            if (imageUrl) {
                                // 更新图片的URL到状态中
                                setImageUrls((prev) => ({
                                    ...prev,
                                    [item.image_file.file_id]: imageUrl,
                                }));
                            }
                        } catch (error) {
                            console.error(
                                `获取图片内容失败: ${item.image_file.file_id}`,
                                error,
                            );
                        }
                    }
                });

                return Promise.all(contentPromises); // 等待所有内容异步操作完成
            });

            await Promise.all(imagePromises); // 确保所有图片获取操作都完成


            // 更新最后一条消息的引用和消息列表
            lastMessage.current = newLastMessage;
            setMessages(messages);
            return messages;
        } catch (error) {
            console.error("获取消息列表失败:", error);
            // Message.error("获取消息列表失败，请稍后再试。"); // 显示错误信息给用户
            return []; // 出错时返回空数组
        }
    };

    // 在对话新建时
    const newThreadHandler = async () => {
        const assistantId = localStorage.getItem("assistant_id");
        const emptyThread = await openai.beta.threads.create();
        setThreadId(emptyThread.id);
        localStorage.removeItem(`${assistantId}-thread_id`);
        localStorage.setItem(
            `${assistantId}-thread_id`,
            JSON.stringify(emptyThread.id),
        );
        setMessages([]);
        return emptyThread.id;
    };


    // 删除消息
    const handleDeleteMessage = async (msg) => {
        setIsUserScrolling(true);
        try {
            if (!msg || !msg.id) {
                console.warn(`Message with ID ${msg.id} is not found.`);
                return;
            }

            if (msg.content[0].type === "image_file") {
                await openai.files.del(msg.content[0].image_file.file_id);
            }
            await openai.beta.threads.messages.del(threadId, msg.id);
            await getMessageList(threadId);
        } catch (error) {
            console.error("Error deleting message:", error);
        } finally {
            setIsUserScrolling(false);
        }
    };

    // 删除消息并刷新
    const handleRefreshMessage = async (msg, msgIndex) => {
        try {
            setIsUserScrolling(true);

            const endIndex = messages.length - 1 - msgIndex;
            const deletePromises = messages
                .slice(0, endIndex + 1)
                .flatMap((message) => {
                    const promises = [];
                    if (message.content[0].type === "image_file") {
                        promises.push(
                            openai.files.del(message.content[0].image_file.file_id),
                        );
                    }
                    if (message.id) {
                        promises.push(openai.beta.threads.messages.del(threadId, message.id));
                    } else {
                        console.warn('Message ID not found for deletion');
                    }
                    return promises;
                });

            await Promise.all(deletePromises);
            await getMessageList(threadId);

            setIsLoadingChat(true);
            await handleSend(threadId, "");
        } catch (error) {
            console.error("Error refreshing messages:", error);
            Message.error("刷新消息时发生错误，请稍后再试。");
        } finally {
            setIsUserScrolling(false);
            setIsLoadingChat(false);
        }
    };

    // 发送消息
    const handleSend = async (threadId, text) => {
        setIsUserScrolling(false);
        setInputValue("");
        setIsLoadingChat(true);

        try {
            if (text?.trim()) {
                await openai.beta.threads.messages.create(threadId, {
                    role: "user",
                    content: text,
                });
                await getMessageList(threadId);
            } else {
                if (messages.length > 1) {
                    if (messages[0].role === "assistant") {
                        if (messages[1].role === "user") {
                            await openai.beta.threads.messages.update(threadId, messages[1].id, {
                                metadata: {
                                    process: "false"
                                },
                            }).then(async () => {
                            });
                        }
                    } else {
                        await openai.beta.threads.messages.update(threadId, messages[0].id, {
                            metadata: {
                                process: "false"
                            }
                        }).then(async () => {
                        });
                    }
                }
                if (messages.length === 1) {
                    if (messages[0].role === "user") {
                        await openai.beta.threads.messages.update(threadId, messages[0].id, {
                            metadata: {
                                process: "false"
                            }
                        }).then(async () => {
                        });
                    }
                }
            }

            // 无论是否有输入，都执行机器人回复
            setIsLoadingChat(true);
            await postRunByThreadBotCloud(
                threadId,
                "",
                salesMode.current,
                selectedEmployee.id,
                chatBackground,
            ).then(async (data) => {
                await handleBotResponse(
                    data,
                    threadId,
                    chatBackground,
                    handleBackgroundChange,
                );
            });
        } catch (error) {
            console.error("Error during message send:", error);
            Message.error("发送消息时出错，请稍后再试。");
        } finally {
            setIsLoadingChat(false);
        }
    };

    const handleBotResponse = async (
        data,
        threadId,
        chatBackground,
        handleBackgroundChange,
    ) => {
        if (!data.result) {
            setIsLoadingChat(false);
            return false;
        }

        try {
            // 处理定时回复
            if (data.second) {
                await scheduleMessageReply(threadId, data.second);
            }

            if (data.new_background) {
                await handleBackgroundChange(data.new_background);
            } else if (data.memory) {
                const newBackground = chatBackground
                    ? chatBackground + "\n" + data.memory // 如果已有背景，添加换行符
                    : data.memory; // 如果没有背景，直接使用memory内容
                await handleBackgroundChange(newBackground);
            }

            await getMessageList(threadId);
            setIsLoadingChat(false);
            return true;
        } catch (processingError) {
            console.error("Error processing bot response:", processingError);
            Message.warning("处理响应时发生错误，但会话仍在继续");
            return false;
        }
    };

    // 清理定时器
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    //设置定时器
    const scheduleMessageReply = (threadId, seconds) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            console.log("定时器来了");
            getMessageList(threadId).then((r) => {
            });
            setIsLoadingChat(false);
        }, seconds * 1000);
    };

    // 上传图片
    const handleUploadPic = (currentFile) => {
        setFile({
            ...currentFile,
            url: URL.createObjectURL(currentFile.originFile),
        });
        setIsLoadingChat(true);

        if (currentFile.status === "uploading") {
            openai.files.create({
                file: currentFile.originFile,
                purpose: 'vision',
            }).then((data) => {
                openai.beta.threads.messages
                    .create(threadId, {
                        role: 'user',
                        content: [
                            {
                                type: 'image_file',
                                image_file: {
                                    file_id: data.id,
                                },
                            },
                        ],
                    })
                    .then(async () => {
                        await getMessageList(threadId).then((data) => {
                            setFile(null);
                            postRunByThreadBotCloud(
                                threadId,
                                draftInstructions,
                                salesMode.current,
                                selectedEmployee.id,
                                chatBackground,
                            ).then((data) => {
                                handleBotResponse(
                                    data,
                                    threadId,
                                    chatBackground,
                                    handleBackgroundChange,
                                ).then((r) => {
                                });
                            });
                        });
                    });
            });
        }
    };


    return (
        <Space
            direction="vertical"
            style={{
                height: "100vh",
                boxShadow: "-2px 0 2px rgba(0, 0, 0, 0.05)",
                paddingLeft: 10,
            }}
        >
            <Space
                style={{
                    width: "30vw",
                    height: 40,
                    marginTop: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h3>聊天测试</h3>
                <Tag color="green" icon={<IconWechat/>}>
                    <h3>{selectedEmployee.name}</h3>
                </Tag>
                <LaunchModal
                    selectedEmployee={selectedEmployee}
                    draftInstructions={roleInstructions}
                    chatMode={chatMode}
                    maxSeats={maxSeats}
                />
            </Space>

            <Space direction="vertical">
                <ChatMessages
                    messages={messages}
                    contentRef={contentRef}
                    isLoadingChat={isLoadingChat}
                    tableData={tableData}
                    handleDeleteMessage={handleDeleteMessage}
                    handleRefreshMessage={handleRefreshMessage}
                    larkImageUrls={larkImageUrls}
                    imageUrls={imageUrls}
                />

                <ChatInput
                    onSend={(text) => handleSend(threadId, text)}
                    onUploadPic={handleUploadPic}
                    onClear={newThreadHandler}
                    threadId={threadId}
                    disableInput={disableValue.current}
                />
            </Space>
        </Space>
    );
};
export default ChatTest;
