// EditPage.js
import React, {useEffect, useRef, useState, useCallback} from "react";
import {
    Input,
    Button,
    Space,
    Message,
    Tabs,
} from "@arco-design/web-react";
import {
    updateAssistantApi,
    getAssistantsIdApi
} from "../../../api/normalApi";
import ChatTest from "./ChatTest";
import {useNavigate, useParams} from "react-router-dom";
import SalesData from "./components/EditPage/SalesData";
import LearnChat from "./components/LearnChat/LearnChat"; // Import LearnChat component
import {useDispatch} from "react-redux";
import {clearSelectedEmployee} from "../../../store/slices/userSlice";
import OpenAI from "openai";

const openai = new OpenAI({
    baseURL: "https://baseurl.replit.app",
    apiKey: "",
    dangerouslyAllowBrowser: true,
});

// 常量定义
const STATUS_SUCCESS = "success";
const DEFAULT_EMPLOYEE_NAME = "未命名的新员工";
const ASSISTANT_INSTRUCTIONS = "你是一个知识检索助手";

const {TextArea} = Input;


// 自定义 Hook 用于获取和更新员工数据
const useFetchAssistantData = (id, setName, setDraftInstructions, setRoleInstructions, setOpenaiIds,setAssistant) => {
    useEffect(() => {
        let isCancelled = false;

        const fetchData = async () => {
            try {
                if (id) {
                    const response = await getAssistantsIdApi(id);
                    if (isCancelled) return;
                    if (response.status === STATUS_SUCCESS) {
                        const data = response.data;
                        setName(data.name || DEFAULT_EMPLOYEE_NAME);
                        setDraftInstructions(data.draft_instructions);
                        setRoleInstructions(data.role_instructions);
                        setAssistant(data);

                        let updateParams = {};
                        let needUpdate = false;

                        if (!data.openai_assistant_id) {
                            const assistantResponse = await openai.beta.assistants.create({
                                instructions: ASSISTANT_INSTRUCTIONS,
                                name: data.name,
                                model: "gpt-4o"
                            });
                            if (isCancelled) return;
                            updateParams.openai_assistant_id = assistantResponse.id;
                            needUpdate = true;
                        }

                        if (!data.openai_vs_id) {
                            const vectorStore = await openai.beta.vectorStores.create({
                                name: data.name
                            });
                            if (isCancelled) return;
                            updateParams.openai_vs_id = vectorStore.id;
                            needUpdate = true;
                        }

                        if (needUpdate) {
                            await updateAssistantApi(id, updateParams);
                            if (isCancelled) return;
                            setOpenaiIds(updateParams);
                        }
                    } else {
                        Message.error("获取员工信息失败");
                    }
                }
            } catch (error) {
                if (!isCancelled) {
                    Message.error("请求失败，请重试");
                    console.error(error);
                }
            }
        };

        fetchData().then(r => {});

        return () => {
            isCancelled = true;
        };
    }, [id, setName, setDraftInstructions, setRoleInstructions, setOpenaiIds,setAssistant]);
};

const EditPage = ({selectedEmployee, maxClientSeats}) => {
    const [draftInstructions, setDraftInstructions] = useState("");
    const [roleInstructions, setRoleInstructions] = useState("");
    const [threadId, setThreadId] = useState("");
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState(DEFAULT_EMPLOYEE_NAME);
    const [chatBackground, setChatBackground] = useState("");
    const [tableData, setTableData] = useState("");
    const [openaiIds, setOpenaiIds] = useState({});
    const [loading, setLoading] = useState(false);
    const [assistant, setAssistant] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const inputRef = useRef(null);

    let {id} = useParams();
    if (!id && selectedEmployee) {
        id = selectedEmployee.id;
    }

    // 使用自定义 Hook 获取员工数据
    useFetchAssistantData(id, setName, setDraftInstructions, setRoleInstructions, setOpenaiIds,setAssistant);

    // 当 threadId 变化时，加载对应的聊天背景
    useEffect(() => {
        if (threadId) {
            const savedBackground = localStorage.getItem(`chat_background_${threadId}`);
            setChatBackground(savedBackground || "");
        } else {
            setChatBackground("");
        }
    }, [threadId]);

    // 当 editing 变化时，如果为 true，将焦点聚焦到输入框
    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    // 通用保存函数
    const handleSave = useCallback(async (field, value, successMessage, errorMessage) => {
        if (!value.trim()) {
            Message.error(`${field}不能为空`);
            return;
        }
        setLoading(true);
        try {
            const response = await updateAssistantApi(id, {[field]: value});
            if (response.status === STATUS_SUCCESS) {
                Message.success(successMessage);
                // 重新获取数据
                await getAssistantsIdApi(id).then(res => {
                    if (res.status === STATUS_SUCCESS) {
                        const data = res.data;
                        setName(data.name || DEFAULT_EMPLOYEE_NAME);
                        setDraftInstructions(data.draft_instructions);
                        setRoleInstructions(data.role_instructions);
                    } else {
                        Message.error("更新后获取数据失败");
                    }
                });
            } else {
                Message.error(`${errorMessage}: ${response.message || ""}`);
                console.error(response);
            }
        } catch (error) {
            Message.error(`${errorMessage}: ${error.message}`);
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [id]);

    const handleSaveName = useCallback(() => {
        handleSave("name", name, "名称已修改", "修改失败");
        setEditing(false);
    }, [handleSave, name]);

    const handleSaveRole = useCallback(() => {
        handleSave("role_instructions", roleInstructions, "已保存", "保存失败");
    }, [handleSave, roleInstructions]);

    const handleSaveBackground = useCallback((value) => {
        setChatBackground(value);
        // 将更改保存到 localStorage，使用 threadId 作为 key
        if (threadId) {
            localStorage.setItem(`chat_background_${threadId}`, value);
        }
    }, [threadId]);

    const handleBack = useCallback(() => {
        dispatch(clearSelectedEmployee());
        navigate(`/employees`);
    }, [dispatch, navigate]);

    return (
        <Space style={{height: "100vh", paddingRight: 8}} align="start">
            {/* 员工信息编辑部分 */}
            <Space
                direction="vertical"
                style={{
                    height: "100vh",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#EEE white",
                    width: "30vw"
                }}
            >
                <Space
                    style={{
                        width: "100%",
                        height: 40,
                        marginTop: 8,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <h3 style={{margin: 0, marginRight: 10}}>员工信息</h3>
                    <Input
                        ref={inputRef}
                        value={name}
                        onChange={(e) => setName(e)}
                        onBlur={handleSaveName}
                        onPressEnter={handleSaveName}
                        placeholder={DEFAULT_EMPLOYEE_NAME}
                        style={{flex: 1, marginRight: 10, minWidth: "10vw", textAlign: "center"}}
                        disabled={loading}
                    />
                    <Button
                        size="small"
                        type="outline"
                        onClick={handleBack}
                        loading={loading}
                    >
                        返回
                    </Button>
                </Space>
                <TextArea
                    style={{
                        maxHeight: "93vh",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#EEE white",
                    }}
                    placeholder="填写人物设定"
                    autoSize={{minRows: 10}}
                    value={roleInstructions}
                    onChange={(v) => setRoleInstructions(v)}
                    onBlur={handleSaveRole}
                    disabled={loading}
                />
            </Space>
            {/* 聊天部分 */}
            <ChatTest
                threadId={threadId}
                setThreadId={setThreadId}
                draftInstructions={draftInstructions}
                setDraftInstructions={setDraftInstructions}
                roleInstructions={roleInstructions}
                tableData={tableData}
                selectedEmployee={selectedEmployee}
                chatBackground={chatBackground}
                handleBackgroundChange={handleSaveBackground}
                maxSeats={maxClientSeats}
            />
             {/*销售资料部分*/}
            <Space
                direction="vertical"
                style={{
                    maxHeight: "96vh",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#EEE white",
                    overflowY: "auto",
                    width: "24vw",
                    padding:5
                }}
            >
                <SalesData
                    assistant={assistant}
                    setTableData={setTableData}
                />
                <h3 style={{margin: 0}}>记忆存储</h3>
                <TextArea
                    placeholder="AI自动整理聊天关键信息记忆"
                    value={chatBackground}
                    onChange={handleSaveBackground}
                    autoSize={{minRows: 3}}
                    style={{width: "100%"}}
                    disabled={!threadId || loading}
                />
                {/*<Tabs defaultActiveTab="1">*/}
                {/*    <Tabs.TabPane key="1" title="销售资料" style={{height:"90vh",width:"100%"}}>*/}
                {/*  */}
                {/*   </Tabs.TabPane>*/}
                {/*    <Tabs.TabPane key="2" title="模仿学习">*/}
                {/*        <LearnChat assistant={selectedEmployee.assistant}/>*/}
                {/*    </Tabs.TabPane>*/}
                {/*</Tabs>*/}
            </Space>
        </Space>
    );
};

export default EditPage;