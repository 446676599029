import React, {useEffect, useState} from 'react';
import {Space, Avatar, Typography, Button, Image, Spin} from '@arco-design/web-react';
import {formatTimestampToDateTime} from '../../../../utils/format';
import OpenAI from "openai";
import {getGroupMemberHeadApi, sendMessageApi} from "../../../../api/normalApi";
import axios from "axios";
import groupSendMessage from "../GroupSendMessage";

const {Text} = Typography;
const openai = new OpenAI({
    baseURL: "https://baseurl.replit.app",
    apiKey: "",
    dangerouslyAllowBrowser: true,
});

const ChatPreview = ({
                         chatItem,
                         prompt,
                         handleSend,
                         currentWxAccount,
                         setGroupSendLoading,
                         checkedItems,
                         setCheckedItems,
                         statusMap, setStatusMap
                     }) => {
    const [messages, setMessages] = useState([]);
    const [replyContent, setReplyContent] = useState("");
    const [messageAvatars, setMessageAvatars] = useState({});
    const [messageImages, setMessageImages] = useState({});
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setReplyContent("");
    }, [prompt]);

    const handleGroupAvatars = async (
        messages,
        currentWxAccount,
        currentWxUser,
        messageAvatars
    ) => {
        try {
            const newMessageAvatars = {...messageAvatars};

            if (messages) {
                for (const message of messages) {
                    const actualUserId = message.metadata?.actual_user_id;
                    if (actualUserId && !newMessageAvatars[actualUserId]) {
                        const cacheKey = `avatar-${currentWxAccount.wx_id}-${currentWxUser.to_wx_id}-${actualUserId}`;

                        try {
                            const cachedAvatar = localStorage.getItem(cacheKey);
                            if (cachedAvatar) {
                                newMessageAvatars[actualUserId] = cachedAvatar;
                                continue;
                            }
                            const response = await getGroupMemberHeadApi(currentWxAccount.wx_id, currentWxUser.to_wx_id, actualUserId);
                            if (response.data?.head_url) {
                                const imageResponse = await axios.get(response.data.head_url, {responseType: "blob",});
                                const imageBlob = imageResponse.data;
                                // 转换blob为base64
                                const reader = new FileReader();
                                reader.readAsDataURL(imageBlob);
                                await new Promise((resolve, reject) => {
                                    reader.onloadend = () => {
                                        const base64data = reader.result;
                                        localStorage.setItem(cacheKey, base64data);
                                        newMessageAvatars[actualUserId] = base64data;
                                        resolve();
                                    };
                                    reader.onerror = reject;
                                });
                            }
                        } catch (error) {
                            console.error("获取头像失败:", error);
                        }
                    }
                }
            }
            return newMessageAvatars;
        } catch (error) {
            console.error("处理群聊头像时出错:", error);
            return messageAvatars;
        }
    };

    const getPictureById = async (fileId) => {
        try {
            // 先检查缓存
            const cachedImage = localStorage.getItem(`image-${fileId}`);
            if (cachedImage) {
                return cachedImage;
            }

            const response = await openai.files.content(fileId);
            const blob = await response.blob();
            // 转换blob为base64并存入缓存
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            await new Promise((resolve, reject) => {
                reader.onloadend = () => {
                    const base64data = reader.result;
                    localStorage.setItem(`image-${fileId}`, base64data);
                    resolve();
                };
                reader.onerror = reject;
            });
            return URL.createObjectURL(blob);
        } catch (error) {
            console.error("Error fetching image:", error);
            return null;
        }
    };

    const fetchMessages = async () => {
        const res = await openai.beta.threads.messages.list(
            chatItem.thread_id, {limit: 5, order: 'desc'}
        );
        const formattedMessages = res.data.map(msg => ({
            id: msg.id,
            content: msg.content,
            timestamp: msg.created_at,
            role: msg.role,
            user: {
                id: msg.metadata?.actual_user_id || '',
                nickname: msg.metadata?.actual_nickname || ''
            },
            metadata: msg.metadata
        }));
        // console.log(formattedMessages);
        setMessages(formattedMessages.reverse());

        const newAvatars = await handleGroupAvatars(
            messages,
            currentWxAccount,
            chatItem,
            messageAvatars
        );
        setMessageAvatars(newAvatars);
    }

    const renderMessageContent = (msg, item, contentIndex) => {
        if (item.type === "text" && item.text && item.text.value) {
            // 用户消息直接显示，不分割
            if (msg.role === "user") {
                return (
                    <Space key={`${msg.id}-${contentIndex}`} align="center">
                        <Text
                            style={{
                                color: "#000000",
                                padding: "10px",
                                borderRadius: "8px",
                                background: "#FFFFFF",
                                display: "inline-block",
                                width: "auto",
                            }}
                        >
                            {item.text.value}
                        </Text>
                    </Space>
                );
            }

            // 检查消息是否包含链接
            const hasLink = /(https?:\/\/[^\s]+)/.test(item.text.value);

            // 如果是销售消息且包含链接，不分割
            if (msg.role === "assistant" && hasLink) {
                return (
                    <Space key={`${msg.id}-${contentIndex}`} align="center">
                        <Text
                            style={{
                                color: "#ffffff",
                                padding: "10px",
                                borderRadius: "8px",
                                background: "#366EF4",
                                display: "inline-block",
                                width: "auto",
                            }}
                        >
                            {item.text.value}
                        </Text>
                    </Space>
                );
            }

            // 其他销售消息保持原有的分割逻辑
            return (
                <React.Fragment key={`${msg.id}-${contentIndex}`}>
                    {item.text.value
                        .split(/(?<=[？?!。])/)
                        .map((sentence, sentenceIndex) => (
                            <Space
                                key={`${msg.id}-${contentIndex}-${sentenceIndex}`}
                                align="center"
                            >
                                <Text
                                    style={{
                                        color: "#ffffff",
                                        padding: "10px",
                                        borderRadius: "8px",
                                        background: "#366EF4",
                                        display: "inline-block",
                                        width: "auto",
                                    }}
                                >
                                    {sentence.replace(/[？?！!。，,；;、]+$/g, "")}
                                </Text>
                            </Space>
                        ))}
                </React.Fragment>
            );
        } else if (item.type === "image_file" && item.image_file?.file_id) {
            const imageUrl = messageImages[item.image_file.file_id];
            return imageUrl ? (
                <Space key={`${msg.id}-${contentIndex}`}>
                    <Image
                        src={imageUrl}
                        alt="消息图片"
                        width={250}
                        style={{
                            height: "auto",
                            borderRadius: "8px",
                            margin: "5px 0",
                        }}
                    />
                </Space>
            ) : (
                <Space key={`${msg.id}-${contentIndex}`}>
                    <Spin dot/>
                </Space>
            );
        }
        return null;
    };

    const triggerTest = async () => {
        setLoading(true);
        try {
            // 先尝试从缓存获取回复
            const cachedReply = localStorage.getItem(`${chatItem.thread_id}-reply`);
            if (cachedReply && cachedReply !== "null") {
                setReplyContent(cachedReply);
            } else if (cachedReply === "null") {
                setReplyContent("AI建议不回复用户");
            } else {
                const res = await handleSend();
                setReplyContent(res.reply);
                localStorage.setItem(`${chatItem.thread_id}-reply`, res.reply);
            }
        } catch (error) {
            console.error('Error in handleTest:', error);
        } finally {
            setLoading(false);
        }
    };

    const reTriggerTest = async () => {
        setLoading(true);
        try {
            const res = await handleSend();
            if (res.reply === null) {
                setReplyContent("AI建议不回复用户");
            } else {
                setReplyContent(res.reply);
            }
            localStorage.setItem(`${chatItem.thread_id}-reply`, res.reply);
        } catch (error) {
            console.error('Error in handleTest:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (chatItem) {
            fetchMessages();
            triggerTest();
        }
        // eslint-disable-next-line
    }, [chatItem]);

    // 加载图片
    useEffect(() => {
        const loadImages = async () => {
            const newImages = {};
            for (const message of messages) {
                for (const content of message.content) {
                    if (content.type === "image_file" && content.image_file?.file_id) {
                        if (!messageImages[content.image_file.file_id]) {
                            const imageUrl = await getPictureById(content.image_file.file_id);
                            if (imageUrl) {
                                newImages[content.image_file.file_id] = imageUrl;
                            }
                        }
                    }
                }
            }
            if (Object.keys(newImages).length > 0) {
                setMessageImages((prev) => ({...prev, ...newImages}));
            }
        };
        if (messages && messages.length > 0) {
            loadImages();
        }
        // eslint-disable-next-line
    }, [messages]);

    async function handelGroupSend() {
        setGroupSendLoading(true);
        console.log('群发的会话:', checkedItems);
        for (const item of checkedItems) {
            const reply = localStorage.getItem(`${item.thread_id}-reply`);
            console.log(`会话 ${item.nickname} 的回复:`, reply);
            // 如果回复存在，且不等于 "null" 且不等于 "AI建议不回复用户"，则发送
            if (reply && reply !== "null" && reply !== "AI建议不回复用户") {
                try {
                    await sendMessageApi({ thread_id: item.thread_id, reply });
                    setStatusMap(prev => ({ ...prev, [item.thread_id]: '完成' }));
                    console.log(`会话 ${item.nickname} 群发成功`);
                } catch (error) {
                    console.error(`发送 ${item.nickname} 消息失败`, error);
                }
            } else {
                // 回复为 "null" 或 "AI建议不回复用户"，则直接标记为“不发”
                setStatusMap(prev => ({ ...prev, [item.thread_id]: '不发' }));
                console.log(`会话 ${item.nickname} 未群发，因为回复为 "${reply}"`);
            }
        }
        setGroupSendLoading(false);
        setCheckedItems([]);
    }

    return (
        <Space
            direction="vertical"
            style={{width: "100%"}}
        >
            <Space align={"center"} style={{
                justifyContent: 'space-between', // 两端对齐
                width: '100%' // 确保容器占满宽度
            }}>
                <h3 style={{margin: 0}}>发送预览</h3>
                <Space>
                    <Avatar
                        shape="square"
                        style={{backgroundColor: "#3370ff", marginLeft: 5}}
                        size={32}
                    >
                        {chatItem?.head_url ? (
                            <img
                                alt="avatar"
                                src={chatItem.head_url}
                                onError={(e) => {
                                    e.target.style.display = "none";
                                }}
                            />
                        ) : (
                            chatItem.nickname?.charAt(0)
                        )}
                    </Avatar>
                    <h3 style={{margin: 0}}>{chatItem ? chatItem.nickname : "请选择"}</h3>
                </Space>
                <Button type={"outline"} onClick={handelGroupSend} size={"small"}>群发</Button>
            </Space>
            <Space style={{
                overflowY: 'auto',
                maxHeight: '86vh',
                scrollbarWidth: 'thin',
                scrollbarColor: "#EEE white",
                width: '100%',
                backgroundColor: "#F5F7FA",
            }} direction={"vertical"}>
                <Space style={{
                    paddingTop: 10,
                    width: '100%'
                }} direction={"vertical"}>
                    {messages.map((msg, index) => (
                        <Space key={msg.id || index} align="start"
                               style={{marginBottom: 10, marginRight: 10, marginLeft: 10}}>
                            {msg.role === "user" ? (
                                    <Avatar
                                        shape="square"
                                        style={{backgroundColor: "#3370ff"}}
                                        size={33}
                                    >
                                        {chatItem.is_group ? (
                                            msg.metadata?.actual_user_id &&
                                            messageAvatars[msg.metadata.actual_user_id] ? (
                                                <img
                                                    alt="avatar"
                                                    // 确保这里传递的是一个有效的 Blob 或 File 对象
                                                    src={
                                                        messageAvatars[
                                                            msg.metadata.actual_user_id
                                                            ] instanceof Blob
                                                            ? URL.createObjectURL(
                                                                messageAvatars[msg.metadata.actual_user_id]
                                                            )
                                                            : messageAvatars[msg.metadata.actual_user_id]
                                                    }
                                                />
                                            ) : (
                                                msg.metadata?.actual_nickname?.charAt(0) ||
                                                chatItem.nickname.charAt(0)
                                            )
                                        ) : chatItem.head_url ? (
                                            <img
                                                alt="avatar"
                                                src={chatItem.head_url}
                                                onError={(e) => {
                                                    e.target.style.display = "none";
                                                }}
                                            />
                                        ) : (
                                            chatItem.nickname.charAt(0)
                                        )}
                                    </Avatar>
                                ) :
                                <Avatar shape="square" style={{backgroundColor: "#3370ff"}} size={33}>
                                    {currentWxAccount.head_url ? (<img alt="avatar"
                                                                       src={currentWxAccount.head_url}/>) : (currentWxAccount.nickname.charAt(0))}
                                </Avatar>
                            }
                            <Space direction="vertical">
                                <Space>
                                    <Text style={{fontSize: '14px', color: '#1D2129'}}>
                                        {chatItem.is_group ? (msg.user?.nickname || chatItem.nickname) :
                                            msg.role === "user" ? msg.metadata?.actual_nickname || chatItem.nickname : currentWxAccount.nickname}
                                    </Text>
                                    <Text type="secondary" style={{fontSize: '12px'}}>
                                        {formatTimestampToDateTime(msg.timestamp)}
                                    </Text>
                                </Space>
                                {msg.content.map((item, contentIndex) =>
                                    renderMessageContent(msg, item, contentIndex)
                                )}
                            </Space>
                        </Space>
                    ))}
                </Space>
            </Space>
            <Space direction="vertical" style={{width: '100%'}}>
                {/* 显示加载 dot 或内容 */}
                {loading ? (
                    <Space
                        align={'center'}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%', // 可根据实际情况调整
                        }}
                    >
                        <Space>
                            <Text>AI正在生成回复</Text>
                        </Space>
                        <Spin dot/>
                    </Space>
                ) : (
                    <Space style={{marginLeft: 10}} align="start">
                        <Avatar size={33}>
                            {currentWxAccount?.head_url ? (
                                <img alt="avatar" src={currentWxAccount.head_url}/>
                            ) : (
                                currentWxAccount.nickname?.charAt(0)
                            )}
                        </Avatar>
                        <Space direction="vertical">
                            <Typography>
                                <Text style={{fontSize: '14px', color: '#1D2129'}}>
                                    {currentWxAccount.nickname}
                                </Text>
                                <Text type="secondary" style={{fontSize: '12px', marginLeft: 8}}>
                                    AI生成的待群发内容
                                </Text>
                                <Button type="text" onClick={reTriggerTest}>重新生成</Button>
                            </Typography>
                            <Space>
                                <div
                                    style={{
                                        backgroundColor: '#FA9550',
                                        color: '#ffffff',
                                        padding: '10px',
                                        borderRadius: '10px',
                                    }}
                                >
                                    {replyContent}
                                </div>
                            </Space>

                        </Space>
                    </Space>
                )}
            </Space>
        </Space>
    );
};

export default ChatPreview;
